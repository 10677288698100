import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../contexts/permissions/PermissionContext";

export const Login:React.FC = () => {
    const [email,setEmail] = useState("");
    const [pw,setPw] = useState<string>("");
    const [msg,setMsg] = useState("");
    //const { setPermissions } = usePermissions();
    const navigate = useNavigate();
    const session_token = localStorage.getItem("token");

    useEffect(()=>{
      if(session_token != null){
        navigate('/')
      }
    },[session_token])

    const handleSubmit = (e: React.SyntheticEvent) => {
      e.preventDefault();
    
   
      async function tryLogin() {
        if (email !== "" && pw !== "") {
          try {
            const response = await fetch("/api/user/login/", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ username: email, password: pw }),
            });
    
            if (response.status !== 200) {
              throw new Error(String(response.status));
            }
    
            const data = await response.json();
            //setPermissions(data.permissions)
            localStorage.setItem("token", data.token);
            localStorage.setItem("is_superuser", data.is_superuser);
            localStorage.setItem("idRole", data.idRole);

            localStorage.setItem("idUser", data.user.id);
            localStorage.setItem("idProfile", data.user.profile);
            
            localStorage.setItem("token", data.token);
            //localStorage.setItem("groups", JSON.stringify(data.groups));
            window.location.reload();
            //navigate("/");
            
          } catch (error) {
            if (String(error).includes("401") || String(error).includes("404")) {
              setMsg("Die Anmeldedaten scheinen nicht korrekt zu sein");
            } else if (String(error).includes("403")) {
              setMsg("Ihr Konto wurde deaktiviert!");
            } else {
              setMsg("Es ist ein unbekannter Fehler aufgetreten. Möglicherweise ist der Dienst vorübergehend offline.");
            }
            setEmail("");
            setPw("");
          }
        } else {
          setEmail("");
          setPw("");
        }
      }
    
      tryLogin();
    };
    
    return(
        <>
            <form onSubmit={handleSubmit} autoComplete="on">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <img alt="logo" width={250} src="/assets/logo.svg" />
                    <Box sx={{ mt: 10 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <TextField
                            label="Benutzername"
                            value={email}
                            fullWidth
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(String(event.target.value))}
                            required
                        />
                    </Grid>

                    <Box sx={{ mt: 2 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <TextField
                            type={'password'}
                            label="Passwort"
                            fullWidth
                            value={pw} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPw(String(event.target.value))}
                            required
                        />

                    </Grid>

                    <Box sx={{ mt: 3 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <div style={{ float: 'right' }}>
                            <Button size="small" variant="contained" color="primary" type="submit">Anmelden</Button>
                        </div>

                    </Grid>

                    <Box sx={{ mt: 3 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        {(msg !== "") &&
                            <Alert severity="error">
                                {msg}
                            </Alert>
                        }
                    </Grid>
                </Grid>
            </form>
        </>
    )
}