import React, { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { ITaskPriority } from "../../interfaces/ITaskPriority";
import { ITaskState } from "../../interfaces/ITaskState";
import { ITaskType } from "../../interfaces/ITaskType";
import { ITask } from "../../interfaces/ITask";
import { Alert, LinearProgress, Typography } from "@mui/material";
import { TaskOverview } from "./Tasks/TaskOverview";
import { IUserSmall } from "../../interfaces/IUser";
import { SalesDasboard } from "./SalesDasboard";
import { IProject } from "../../interfaces/IProject";


export const DashboardOverview:React.FC = () => {
    const [taskPriorityArray, setZaskPriorityArray, statusCodeTaskPriorityArray] = useFetch<ITaskPriority[]>("/task/priority/");
    const [taskStateArray, setTaskStateArray, statusCodeTaskStateArray] = useFetch<ITaskState[]>("/task/state/");
    const [taskTypeArray, setTaskTypeArray, statusCodeTaskTypeArray] = useFetch<ITaskType[]>("/task/type/");
    const [taskArray, setTaskArray, statusCodeTaskArray] = useFetch<ITask[]>("/task/");
    //

    const [userSmallArray, setUserSmallArray, statusCodeProfileArraySmall] = useFetch<IUserSmall[]>("/user/profile/small/");
    //
    const is_superuser = localStorage.getItem("is_superuser") === "true"


    if (
        (statusCodeTaskPriorityArray !== null && statusCodeTaskPriorityArray !== 200)
        || (statusCodeTaskStateArray !== null && statusCodeTaskStateArray !== 200)
        || (statusCodeTaskTypeArray !== null && statusCodeTaskTypeArray !== 200)
        || (statusCodeTaskArray !== null && statusCodeTaskArray !== 200)
        || (statusCodeProfileArraySmall !== null && statusCodeProfileArraySmall !== 200)
    ) {
        return <Alert severity="error">Fehler!</Alert>
    } else if (
        taskPriorityArray === undefined
        || taskStateArray === undefined
        || taskTypeArray === undefined
        || taskArray === undefined
        || userSmallArray === undefined
    ) {
        return <LinearProgress/>
    } else {
        return (
            <>
                <Typography variant="h4">Dashboard</Typography>
                <SalesDasboard
                    userSmallArray={userSmallArray}
                />
                <TaskOverview
                    taskArray={taskArray}
                    setTaskArray={setTaskArray}
                    taskPriorityArray={taskPriorityArray}
                    taskStateArray={taskStateArray}
                    taskTypeArray={taskTypeArray}
                    userSmallArray={userSmallArray}
                />
            </>
        )
    }
}