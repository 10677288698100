import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput, IconButton
} from '@mui/material';
import {ICalendarEvent} from '../../interfaces/ICalendarEvent';
import {IProfile} from "../../interfaces/IUser";
import {Box} from "@mui/system";
import DeleteIcon from '@mui/icons-material/Delete';

// Hilfsfunktion: Konvertiert einen Datums-String in das von datetime-local erwartete Format.
const formatForDatetimeLocal = (dateString: string): string => {
    if (dateString.includes('Z')) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    return dateString.replace(' ', 'T').slice(0, 16);
};

interface CalendarEventEditProps {
    open: boolean;
    event: ICalendarEvent | null;
    onClose: () => void;
    onSave: (updatedEvent: ICalendarEvent) => void;
    userArray: IProfile[];
    onDelete: () => void;
}

const CalendarEventEdit: React.FC<CalendarEventEditProps> = ({open, event, onClose, onSave, userArray, onDelete}) => {
    const [title, setTitle] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [description, setDescription] = useState('');
    const [who, setWho] = useState('');
    const [where, setWhere] = useState('');
    const [wholeDay, setWholeDay] = useState(false);
    const [selectedSubCalendars, setSelectedSubCalendars] = useState<string[]>([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    // Error states für Pflichtfelder
    const [titleError, setTitleError] = useState(false);
    const [startError, setStartError] = useState(false);
    const [endError, setEndError] = useState(false);

    useEffect(() => {
        if (event) {
            setTitle(event.title);
            setStart(event.start ? formatForDatetimeLocal(event.start) : '');
            setEnd(event.end ? formatForDatetimeLocal(event.end) : '');
            setDescription(event.extendedProps?.description || '');
            setWho(event.extendedProps?.who || '');
            setWhere(event.extendedProps?.where || '');
            setWholeDay(event.extendedProps?.wholeDay || false);
            setSelectedSubCalendars(event.extendedProps?.subCalendars || []);
            setTitleError(false);
            setStartError(false);
            setEndError(false);
        } else {
            setTitle('');
            setStart('');
            setEnd('');
            setDescription('');
            setWho('');
            setWhere('');
            setWholeDay(false);
            setSelectedSubCalendars([]);
            setTitleError(false);
            setStartError(false);
            setEndError(false);
        }
    }, [event]);

    const handleSave = () => {
        let valid = true;
        if (!title.trim()) {
            setTitleError(true);
            valid = false;
        }
        if (!start) {
            setStartError(true);
            valid = false;
        }
        if (!end) {
            setEndError(true);
            valid = false;
        }
        if (!valid) return;
        if (event) {
            const updatedEvent: ICalendarEvent = {
                ...event,
                title,
                start,
                end,
                extendedProps: {
                    ...event.extendedProps,
                    description,
                    who,
                    where,
                    wholeDay,
                    subCalendars: selectedSubCalendars,
                },
            };
            onSave(updatedEvent);
        }
    };

    // Verfügbare Kalender basierend auf dem userArray
    const availableSubCalendars = userArray
        .filter(user => user.sub_Calendar && user.sub_Calendar.trim() !== "")
        .map(user => user.sub_Calendar);

    return (
        <>
            <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
                <DialogTitle>Termin löschen</DialogTitle>
                <DialogContent>
                    Möchten Sie diesen Termin wirklich löschen?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={() => {
                        setConfirmDeleteOpen(false);
                        onDelete();
                    }} color="error" variant="contained">
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <span>{(event?.id != "") ? "Termin bearbeiten" : "Neuen Termin erstellen"}</span>
                        <IconButton onClick={() => setConfirmDeleteOpen(true)} color="error">
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Titel"
                        fullWidth
                        margin="normal"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            if (e.target.value.trim()) setTitleError(false);
                        }}
                        required
                        error={titleError}
                        helperText={titleError ? "Titel ist erforderlich" : ""}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Start"
                                type="datetime-local"
                                fullWidth
                                margin="normal"
                                value={start}
                                onChange={(e) => {
                                    setStart(e.target.value);
                                    if (e.target.value) setStartError(false);
                                }}
                                InputLabelProps={{shrink: true}}
                                required
                                error={startError}
                                helperText={startError ? "Startzeit ist erforderlich" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Ende"
                                type="datetime-local"
                                fullWidth
                                margin="normal"
                                value={end}
                                onChange={(e) => {
                                    setEnd(e.target.value);
                                    if (e.target.value) setEndError(false);
                                }}
                                InputLabelProps={{shrink: true}}
                                required
                                error={endError}
                                helperText={endError ? "Endzeit ist erforderlich" : ""}
                            />
                        </Grid>
                    </Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={wholeDay}
                                onChange={(e) => setWholeDay(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Ganztägiges Event"
                    />
                    <TextField
                        label="Beschreibung"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextField
                        label="Wer"
                        fullWidth
                        margin="normal"
                        value={who}
                        onChange={(e) => setWho(e.target.value)}
                    />
                    <TextField
                        label="Wo"
                        fullWidth
                        margin="normal"
                        value={where}
                        onChange={(e) => setWhere(e.target.value)}
                    />
                    {/* Dropdown für Mehrfachauswahl der Kalender */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="subcalendar-select-label">Kalender zuweisen</InputLabel>
                        <Select
                            labelId="subcalendar-select-label"
                            multiple
                            value={selectedSubCalendars}
                            onChange={(e) => setSelectedSubCalendars(e.target.value as string[])}
                            input={<OutlinedInput label="Kalender zuweisen"/>}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                        >
                            {availableSubCalendars.map((cal, index) => (
                                <MenuItem key={index} value={cal}>
                                    {cal}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CalendarEventEdit;
