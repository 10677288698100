import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Typography, Box, Collapse, TextField, Alert, Card, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IProfile, IUser } from "../../interfaces/IUser";
import { CheckBox } from "@mui/icons-material";
import { PermissionRow } from "./PermissionRow";
import ReactPasswordChecklist from "react-password-checklist";
import { IPermission } from "../../interfaces/IPermission";
import { useGetUser } from "../../hooks/data/data_profile";
import { IRole } from "../../interfaces/IRole";

interface IProps {
    workerObject: IProfile;
    roleArray: IRole[];
    setWorkerObject: Function;
    setUserPassword: Function;
}

export const getEmptyUser = () => {
    return { 
          id: 0,
          email: '',
          username: '',
          is_superuser: false,
          profile: '',
          first_name: '',
          last_name: '',
          password: '',
      } as IUser
}



export const ProfileEdit: React.FC<IProps> = ({ workerObject, roleArray, setWorkerObject, setUserPassword}) => {
    const is_superuser = localStorage.getItem("is_superuser") === "true"

    const [userObject, setUserObject] = useState<IUser>(workerObject.User);
    const [disableUserName, setDisableUsername] = useState(false);
    //const [permissionArray,setPermissionArray] = useState(workerObject.permissions);

    const [showPW,setshowPW] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");
    const [isValid, setIsValid] = useState(false);

    const stupidTranslation = (appName:string) => {
        return (appName === "address") ?  "Adressen"
        : (appName === "bill") ?  "Rechnungen"
        : (appName === "company") ?  "Firmen"
        : (appName === "country") ?  "Land"
        : (appName === "customer") ?  "Kunden"
        : (appName === "document") ?  "Dokumente"
        : (appName === "insurance") ?  "Pflegekassen"
        : (appName === "offer") ?  "Angebote"
        : (appName === "partner") ?  "Partner"
        : (appName === "postcode") ?  "Postleitzahlen"
        : (appName === "project") ?  "Projekte"
        : (appName === "propertymanagement") ?  "Hausverwaltungen"
        : (appName === "services") ?  "Leistungsverzeichnis"
        : (appName === "material") ?  "Materialverzeichnis"
        : (appName === "unit") ?  "Einheiten"
        : (appName === "user") ?  "Benutzer"
        : (appName === "handoverprotocol") ?  "Übergabeprotokoll"
        : appName
    }

    useEffect(()=>{
        if (isValid) {
            setWorkerObject({
                ...workerObject,
                User: {
                    ...userObject,
                    password: newPassword
                }
            });
        } else {
            setWorkerObject({
                ...workerObject,
                User: userObject
            });
        }

    },[isValid,userObject])




    if (workerObject.id > 0 && workerObject === undefined) {
        return <CircularProgress />;
    } else {
        return (
            <>
                <Collapse in={showPW && !isValid}>
                    <Alert severity="warning">
                        Bitte geben Sie ein valdies Passwort ein. Andernfalls wird das neue Passwort nicht übernommen!
                    </Alert>
                </Collapse>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={4}>
                        <CustomeTextField
                            label="Admin"
                            attr="is_superuser"
                            type="boolean"
                            object={userObject}
                            setObject={setUserObject}
                            disabled={!is_superuser}
                        />
                    </Grid>
                    <Grid item sm={8}>
                        <CustomeTextField
                            label="Benutzername"
                            attr="username"
                            type="string"
                            object={userObject}
                            setObject={setUserObject}
                            disabled={disableUserName}
                            required
                        />
                    </Grid>



                    <Grid item sm={12}>
                        <Box sx={{ mt: 4}}/>
                        <CustomeTextField
                            label="Rolle"
                            attr="Role"
                            type="select"
                            object={workerObject}
                            setObject={setWorkerObject}
                            disabled={disableUserName}
                            required

                        >
                            {roleArray?.map(role =>
                                <MenuItem 
                                    key={`role-${role.id}`}
                                    value={role.id}
                                >
                                    {role.role}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                        <Box sx={{ mb: 4}}/>
                    </Grid>



                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Vorname"
                            attr="first_name"
                            type="string"
                            object={userObject}
                            setObject={setUserObject}
                            required
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Nachname"
                            attr="last_name"
                            type="string"
                            object={userObject}
                            setObject={setUserObject}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <CustomeTextField
                            label="Email"
                            attr="email"
                            type="string"
                            object={userObject}
                            setObject={setUserObject}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Mobil"
                            attr="mobile_number"
                            type="string"
                            object={workerObject}
                            setObject={setWorkerObject}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Telefon"
                            attr="phone_number"
                            type="string"
                            object={workerObject}
                            setObject={setWorkerObject}
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            label="Kalenderfarbe"
                            type="color"
                            value={workerObject.calendar_color || "#000000"}
                            onChange={(e) =>
                                setWorkerObject({ ...workerObject, calendar_color: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            size="small"
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: '40px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Kalender"
                            attr="sub_Calendar"
                            type="string"
                            object={workerObject}
                            setObject={setWorkerObject}
                        />
                    </Grid>
                </Grid>
                
                <Box sx={{ mt: 5 }}/>
                <Collapse in={!showPW}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <TextField
                                label="Passwort"
                                value="Geh****"
                                size="small"
                                fullWidth
                                onFocus={() => setshowPW(true)}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
                <Collapse in={showPW}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <TextField
                                type="password"
                                label="Passwort"
                                value={newPassword}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setNewPassword( event.target.value )}}
                                fullWidth
                                size="small"
                                onBlur={() => setshowPW(newPassword !== "")}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                type="password"
                                label="Passwort (wiederholen)"
                                value={newPasswordAgain}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setNewPasswordAgain( event.target.value )}}
                                fullWidth
                                size="small"
                                onBlur={() => setshowPW(newPassword !== "")}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <ReactPasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                minLength={8}
                                value={newPassword}
                                valueAgain={newPasswordAgain}
                                onChange={(isValid) => setIsValid(isValid)}
                                messages={{
                                    minLength: "Das Passwort hat 8 Zeichen.",
                                    specialChar: "Das Passwort hat ein Sonderzeichen.",
                                    number: "Das Passwort hat eine Zahl.",
                                    capital: "Das Passwort hat ein Großbuchstaben.",
                                    match: "Die Passwörter stimmen überein",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
                
                {/*
                <Box sx={{ mt: 5 }}/>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {userObject.id === 0 ?
                            <Card variant="outlined">
                                <Typography sx={{m: 2}}>
                                    Die Zugriffsverwaltung ist nach dem Erstellen des Benutzers verfügbar.
                                </Typography>
                            </Card>
                            :
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Zugriffsrechte zuweisen</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell sx={{width: 50}}>Vollzugriff</TableCell>
                                                        <TableCell sx={{width: 50}}>Lesen</TableCell>
                                                        <TableCell sx={{width: 50}}>Bearbeiten</TableCell>
                                                        <TableCell sx={{width: 50}}>Erstellen</TableCell>
                                                        <TableCell sx={{width: 50}}>Löschen</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {permissionArray
                                                        .map(x => {return {...x, app_de: stupidTranslation(x.app)}}  )
                                                        .sort((a,b) => a.app_de.toLocaleLowerCase() < b.app_de.toLocaleLowerCase() ? -1 : 1)
                                                        .map(x =>
                                                            <>
                                                                <PermissionRow
                                                                    key={`app-${x.app}`}
                                                                    permissionObject={x}
                                                                    permissionArray={workerObject.permissions}
                                                                    setPermissionArray={(per:IPermission[]) => setWorkerObject({...workerObject, permissions: per} as IProfile)}
                                                                    userIsAdmin={userObject.is_superuser}
                                                                />
                                                            </>
                                                    )}
                                                </TableBody>

                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                        }

                    </Grid>
                </Grid>
                 */}
            </>
        );
    }
};
