import React, { useEffect } from "react";
import { IProject } from "../../interfaces/IProject";
import { IUserSmall } from "../../interfaces/IUser";
import { Box, Card, CardContent, Chip, Grid, Typography, useTheme } from "@mui/material";
import { Cell, Pie, PieChart, PieLabel, ResponsiveContainer } from "recharts";
import { IProjectState } from "../../interfaces/IProjectState";
import { IChartData } from "./SalesDasboard";
import { ProjectDistributionChart } from "./ProjectDistributionChart";
import { IProjectLog } from "../../interfaces/IProjectLog";


interface IProps {
    logArray: IProjectLog[];
    projectSmallArray: IProject[];
    projectStateArray: IProjectState[];
    currentUserSamll?: IUserSmall;
    fromDate: Date | null;
    toDate: Date | null;
}




export const SalesBoardUser:React.FC<IProps> = ({
    logArray,
    projectSmallArray,
    projectStateArray,
    currentUserSamll,
    fromDate,
    toDate
}) => {
    const thmea = useTheme();

    const projectByUser = projectSmallArray.filter(project => currentUserSamll == undefined || currentUserSamll.profile === null ? true : project.Worker.indexOf(currentUserSamll.profile.id) !== -1);
    
    
    const conversionRateDenominator = projectByUser.filter(project =>
        logArray
            .filter(log =>  log.to_state >= 3 && log.to_state <= 13 )
            .filter(log =>
                (fromDate === null || toDate === null) ? false
                : new Date(log.created_at) >= fromDate && new Date(log.created_at) <= toDate
            )
            .map(log => log.Project)
            .indexOf(project.id) !== -1
    ).length

    const conversionRateNumerator = projectByUser.filter(project =>
        logArray
            .filter(log =>  log.to_state >= 1 && log.to_state <= 13 )
            .filter(log =>
                (fromDate === null || toDate === null) ? false
                : new Date(log.created_at) >= fromDate && new Date(log.created_at) <= toDate
            )
            .map(log => log.Project)
            .indexOf(project.id) !== -1
    ).length

    const getConversionRate = conversionRateDenominator/conversionRateNumerator;
    const getTotalConversionRate = projectByUser.filter(
        x => x.ProjectState === 3
    ).length / projectByUser.length;


    const closingRateDenominator = projectByUser.filter(project =>
        logArray
            .filter(log =>  log.to_state >= 5 && log.to_state <= 11)
            .filter(log =>
                (fromDate === null || toDate === null) ? false
                : new Date(log.created_at) >= fromDate && new Date(log.created_at) <= toDate
            )
            .map(log => log.Project)
            .indexOf(project.id) !== -1
    ).length
    const closingRateNumerator = projectByUser.filter(project =>
        logArray
            .filter(log =>  log.to_state >= 4 && log.to_state <= 13)
            .filter(log =>
                (fromDate === null || toDate === null) ? false
                : new Date(log.created_at) >= fromDate && new Date(log.created_at) <= toDate
            )
            .map(log => log.Project)
            .indexOf(project.id) !== -1
    ).length
    const getClosingRate = closingRateDenominator/closingRateNumerator;
    const getTotalClosingRate = projectByUser.filter(
        x => x.ProjectState === 6 || x.ProjectState === 7
    ).length / projectByUser.length;





    const chartData = () :IChartData[] => {
        let returnArray:IChartData[] = [];
        projectStateArray.map(state => returnArray.push({
            name: state.projectState,
            value: projectByUser.filter(project => project.ProjectState === state.id).length
        }))

        return returnArray;
    }



    return(
        <>

            <Grid item xs={12} lg={6} xl={4}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h4">{currentUserSamll == undefined ? `Ganzes Team` : `${currentUserSamll.first_name} ${currentUserSamll.last_name}`}</Typography>
                        
                        {
                            ( currentUserSamll === undefined || [3,4].indexOf(Number(currentUserSamll.profile?.Role)) > -1) &&
                            <>
                                <Typography variant="h6" sx={{mt: 2}}>Conversion Rate</Typography>
                                <Box sx={{display: "flex"}}>
                                    <Typography
                                        sx={{
                                            ml: 10,
                                        }}
                                        variant="h4"
                                        color={thmea.palette.grey[400]}
                                    >
                                        {(getTotalConversionRate*100).toFixed(2).replace(".",",") + " %"}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            ml: 10,
                                        }}
                                        variant="h4"
                                    >
                                        {(getConversionRate*100).toFixed(2).replace(".",",") + " %"}
                                    </Typography>
                                </Box>
                            </>
                        }

                        {
                            ( currentUserSamll === undefined || [1,5,6].indexOf(Number(currentUserSamll.profile?.Role)) > -1) &&
                            <>
                                <Typography variant="h6" sx={{mt: 2}}>Abschlussquote</Typography>
                                <Box sx={{display: "flex"}}>
                                    <Typography
                                        sx={{
                                            ml: 10,
                                        }}
                                        variant="h4"
                                        color={thmea.palette.grey[400]}
                                    >
                                        {(getTotalClosingRate*100).toFixed(2).replace(".",",") + " %"}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            ml: 10,
                                        }}
                                        variant="h4"
                                    >
                                        {(getClosingRate*100).toFixed(2).replace(".",",") + " %"}
                                    </Typography>
                                </Box>
                            </>
                        }

                        <ProjectDistributionChart
                            chartData={chartData()}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    )

} 