import React, { useRef, useState } from "react";
import { IDocument } from "../../../interfaces/IDocument";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { PDFViewer } from "../../core/PDFViewer";
import { CustomCircularProgress } from "../../core/CustomCircularProgress";
import ReactSignatureCanvas from "react-signature-canvas";
import { useCreateSignedContract } from "../../../hooks/data/data_contract";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";
import { base64Download } from "../../core/generic";

export interface ISignedContract {
    Contract: number;
    Document_Signed: IDocument;
    Document_Signed_Partner: IDocument | null;
}

interface IProps {
    idContract: number;
    generatedData: IDocument | null;
    enablePartnerSigned: boolean;
    handleClose: () => void;
    handleSave: () => void;
}

export const SignedContract:React.FC<IProps> = ({idContract,generatedData,enablePartnerSigned,handleClose,handleSave}) => {
    const [isLoading,setIsLoading] = useState(false);
    const refPartner = useRef<ReactSignatureCanvas | null>(null);
    const refCustomer = useRef<ReactSignatureCanvas | null>(null);
    //
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)
    //
    const {createSignedContract, isPendingCreateSignedContract} = useCreateSignedContract();


    const handleUpload = async () => {
        let base64StringPartner = refPartner.current?.toDataURL();
        let base64StringCustomer = refCustomer.current?.toDataURL();

        if (base64StringCustomer !== undefined) {
                let uploadObject: ISignedContract = {
                    Contract: idContract,
                    //HandoverProtocolPartner: currentApplicationDocument.HandoverProtocolPartner,
                    //HandoverApplicationDocumentAnswerArray: answerArray,
                    Document_Signed_Partner: null,
                    Document_Signed: {
                        file_name: "signed_customer.png",
                        data: base64StringCustomer.split(",")[1],
                        id: -1,
                        MIMEtype: {id: 2, MIMEtype: "image/png", file_ending: "png"}
                    }
                }
                try {
                    let data = await createSignedContract(uploadObject);
                    setFeedback({ open: true,message: "Der Vertrag wurde erfolgreich gespeicher!", severity: "success" })
                    base64Download(data);
                    handleSave();
                } catch (error) {
                    console.error(error);
                    setFeedback({ open: true,message: "Es ist ein Fehler aufgeretten!", severity: "error" })
                }
        }
    }



    if (generatedData === null) {
        return <CustomCircularProgress/>
    }

    return(
        <>
            <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

            <Typography variant="h4">
                Vertrag
                <Button
                    variant='contained'
                    disabled={isPendingCreateSignedContract}
                    onClick={handleUpload}
                    sx={{ml: 2, float: "right"}}
                >
                    {isPendingCreateSignedContract ? (
                    <Box display='flex' alignItems='center' gap={1}>
                        <span>Speichern</span>
                        <CircularProgress size={20} />
                    </Box>
                    ) : (
                        'Speichern'
                    )}
                </Button>
            </Typography>

            <Box sx={{mt: 5}}/>
            <PDFViewer documentObject={generatedData} />

            

            <Box sx={{ float: "right", mt: 5 }}>
                
                {(enablePartnerSigned) &&
                    <>
                    <Box sx={{ mt: 15 }} />
                    <Typography>Unterschrift Handwerkspartner</Typography>
                    <ReactSignatureCanvas
                        penColor='black'
                        backgroundColor='rgba(238,238,238,1)'
                        canvasProps={{ width: 800, height: 300, className: 'sigCanvas' }}
                        ref={refPartner}
                    />
                    </>
                }


                <Box sx={{ mt: 10 }} />
                <Typography>Unterschrift Autraggeber</Typography>
                <ReactSignatureCanvas
                    penColor='black'
                    backgroundColor='rgba(238,238,238,1)'
                    canvasProps={{ width: 800, height: 300, className: 'sigCanvas' }}
                    ref={refCustomer}
                />

            </Box>
        </>
    )

}