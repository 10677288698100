import { useQuery } from "@tanstack/react-query";
import { IProfile } from "../../interfaces/IUser";
import { IRole } from "../../interfaces/IRole";
import { customFetcher } from "../useFetch";


export const useGetUser = () => {
    const {
      data: roleArray,
      isLoading: isLoadingRoleArray,
      refetch: refetchRoleArray,
    } = useQuery<IRole[]>({
      queryKey: ['user/role/'],
      queryFn: () => customFetcher({ endpoint: '/user/role/' }),
    });
    return { roleArray, isLoadingRoleArray, refetchRoleArray};
  };