import React, { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { ISystemMailCategory } from "../../interfaces/ISystemMailCategory";
import { IDictionary } from "../../interfaces/IDictionary";
import { DataGrid, deDE, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Check } from "@mui/icons-material";
import { IDictionaryEntry } from "../../interfaces/IDictionaryEntry";
import { Alert, Box, Button, Dialog, DialogContent, MenuItem, TextField, Typography } from "@mui/material";
import { CustomCircularProgress } from "../core/CustomCircularProgress";
import { SystemMailEdit } from "./SystemMailEdit";
import { useGetOfferState } from "../../hooks/data/data_offer";
import { useGetProjectState } from "../../hooks/data/data_project";
import { useGetTaskState } from "../../hooks/data/data_task";
import { useGetBillState } from "../../hooks/data/data_bill";
import { useGetSystemMailSendCategory } from "../../hooks/data/data_systemmail";





export const SystemMailOverview:React.FC = () => {
    //const dictionaryEntryArray = useSelector((state: State) => state.dictionaryEntryArray.storeDictionaryEntry);
    //
    const [mailArray, setMailArray, statusCodeMailArray] = useFetch<ISystemMail[]>("/systemmail/");
    const [categoryArray, setCategoryArray, statusCodeCategoryArray] = useFetch<ISystemMailCategory[]>("/systemmail/category/");
    const [dictonaryArray, setDictonaryArray, statusCodeDictonary] = useFetch<IDictionary[]>("/systemmail/dictionary/");
    const [dictionaryEntryArray, setDictionaryEntryArray, statusCodeDictionaryEntryArray] = useFetch<IDictionaryEntry[]>("/systemmail/dictionary/entry/");
    //
    const {systemMailSendCategoryArray, isLoadingSystemMailSendCategoryArray, refetchSystemMailSendCategory} = useGetSystemMailSendCategory();
    const {offerStateArray, isLoadingOfferState, refetchOfferState} = useGetOfferState();
    const {projectStateArray, isLoadingProjectStateArray, refetchProjectState} = useGetProjectState();
    const {taskStateArray, isLoadingTaskState, refetchTaskStateTask} = useGetTaskState();
    const {billStateArray, isLoadingBillState, refetchBillStateTask} = useGetBillState();
    //
    const [showPublic, setShowPublic] = useState(false);
    const [selectedCategory,setSelectedCategory] = useState(0);
    //
    const [idSystemMail, setIdSystemMail] = useState<number|null>(null);
    //
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false);
    //
    const [scopeArray,setCcopeArray] = React.useState(localStorage.getItem("scope")?.split(","))


    const isLoading =
        isLoadingSystemMailSendCategoryArray
        || isLoadingOfferState
        || isLoadingProjectStateArray
        || isLoadingTaskState
        || isLoadingBillState;

    const refetchAll = () => {
        refetchOfferState();
        refetchProjectState();
        refetchTaskStateTask();
        refetchBillStateTask();
    }


    const columns: GridColDef[] = [
        { field: 'id', 
          headerName: 'Nr.', 
          width: 90 
        },
        { 
            field: 'SystemMailCategory', 
            headerName: 'Kategorie', 
            valueGetter: (params: GridValueGetterParams) => categoryArray?.find(x => x.id === params.row.SystemMailCategory)?.systemMailCategory,
            width: 200,
        },
        /*{ 
            field: 'Variable', 
            headerName: 'Variable', 
            valueGetter: (params: GridValueGetterParams) => 
                mailHasDictionaryArray?.filter(x => x.idSystemMail === params.row.idSystemMail)
                    .map(dictonary => dictionaryEntryArray === undefined ? 0 : dictionaryEntryArray?.filter(x => x.idDictionary === dictonary.idDictionary).length)
                    .reduce((a,b) => a+b,0),

            width: 100,
        },*/
        { field: 'title', 
          headerName: 'Bezeichnung', 
          flex: 1
        },
    ]


    const handleAddNew = () => {
        setIdSystemMail(-1);
    }


    if (isLoading) { return <CustomCircularProgress/>}
    
    if (!offerStateArray
        || !projectStateArray
        || !taskStateArray
        || !billStateArray
        || !systemMailSendCategoryArray
    ) {
        return(
            <Box
              sx={{
                minHeight: '50vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <Typography variant='h6'>
                Fehler beim Laden der Daten. Bitte versuchen Sie es erneut.
              </Typography>
              <Button
                variant='contained'
                onClick={() => refetchAll()}
              >
                Erneut versuchen
              </Button>
            </Box>
          )
    }



    if (
        (statusCodeMailArray !== null && statusCodeMailArray !== 200)
        || (statusCodeCategoryArray !== null && statusCodeCategoryArray !== 200)
        || (statusCodeDictonary !== null && statusCodeDictonary !== 200)
        || (statusCodeDictionaryEntryArray !== null && statusCodeDictionaryEntryArray !== 200)
        
    ) {
        return <Alert severity="error">Fehler!</Alert> 
    }
    else if (
        mailArray === undefined
        || dictionaryEntryArray === undefined
    ) {
        return <CustomCircularProgress/>
    } else {
        return(
            <>
                {/*
                <ShowWasSaved
                    wasSaved={wasSaved}
                    setWasSaved={setWasSaved}
                    wasSuccessfullySaved={wasSavedSuccesfully}
                />
                */}

                

                <Dialog open={idSystemMail !== null} onClose={() => setIdSystemMail(null)} maxWidth="lg" fullWidth>
                    <DialogContent>
                        {(idSystemMail !== null) &&
                            <SystemMailEdit 
                                key={`idSystemMail-${idSystemMail}`}
                                currentId={idSystemMail}
                                setCurrentId={setIdSystemMail}
                                currentArray={mailArray}
                                setCurrentArray={setMailArray}
                                setWasSaved={setWasSaved}
                                setWasSavedSuccesfully={setWasSavedSuccesfully}
                                dictionaryEntryArray={dictionaryEntryArray}
                                //
                                systemMailSendCategoryArray={systemMailSendCategoryArray || []}
                                billStateArray={billStateArray}
                                offerStateArray={offerStateArray}
                                projectStateArray={projectStateArray}
                                taskStateArray={taskStateArray}
                            />
                        }

                    </DialogContent>
                </Dialog>

                <Typography variant="h4">
                    Systemnachrichten
                </Typography>

                <Typography variant="h5" sx={{mt: 5}}>
                    E-Mail-Vorlagen

                    <Button variant="contained" sx={{float: "right", ml: 2}} onClick={handleAddNew}>Neue Vorlage</Button>

                    <TextField
                        label="Kategorie"
                        size="small"
                        select
                        sx={{float: "right", width: 200}}
                        value={selectedCategory}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setSelectedCategory(Number(event.target.value))}
                    >
                        <MenuItem key={`systemmailcategory-0`} value={0}>Alle</MenuItem>
                        {categoryArray?.map(x =>
                            <MenuItem key={`systemmailcategory-${x.id}`} value={x.id}>{x.systemMailCategory}</MenuItem>
                        )}
                    </TextField>
                    
                </Typography>

                <Box sx={{mt: 2}}/>
                <DataGrid
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={
                        mailArray
                            .filter(x => selectedCategory === 0 ? true : x.SystemMailCategory === selectedCategory)
                    }
                    columns={columns}
                    onRowClick={(params,event,details) => setIdSystemMail(Number(params.id))}
                />
            </>
        )
    }

    
}
