import {Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, TextField, Typography, useScrollTrigger} from "@mui/material";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowParams, deDE} from "@mui/x-data-grid";
import {Delete} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {CustomSnackbar} from '../../components/core/Snackbar';
import {InsuranceEdit} from "../../components/insurance/InsuranceEdit";
import {uploadFetch, useFetch, deleteFetch} from "../../hooks/useFetch";

//interfaces imports
import {IFeedback} from "../../interfaces/IFeedback";
import {IInsurance} from "../../interfaces/IInsurance";
import {IPostcode} from "../../interfaces/IPostcode";
import {IAddressType} from "../../interfaces/IAddress";
import { usePermissions } from "../../contexts/permissions/PermissionContext";


export const getEmptyInsurance = () => {
    return {
        id: 0,
        insurance_name: '',
        Address: {
            Address_Type: 2,
            street: '',
            street_number: '',
            Postcode: 1,
        },
        telefon: '',
        info: '',
        email: '',
    } as IInsurance
}


export const InsuranceOverview: React.FC = () => {
    const [insuranceData, setInsuranceData, statusCode] = useFetch<IInsurance[]>("/insurance/");
    const [addressTypeData, setaddressTypeData] = useFetch<IAddressType[]>("/address/types/")

    //const { permissions } = usePermissions();

    const [editInsurance, setEditInsurance] = useState(getEmptyInsurance());
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [isLoadgin, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    //
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)

    // Suche
    const [searchTerm, setSearchTerm] = useState("");


    //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [insuranceIdToDelete, setInsuranceIdToDelete] = React.useState<number | null>(null);
    const is_superuser = localStorage.getItem("is_superuser")

    const openDeleteConfirmationDialog = (projectId: number) => {
        setInsuranceIdToDelete(projectId);
        setOpenDeleteDialog(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setInsuranceIdToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleRemove = (insuranceId: number) => {
        if (is_superuser === "true"){
            deleteFetch("/insurance/", insuranceId,setWasSuccessfully)
            setFeedback({ open: true,message: "Pflegekasse erfolgreich gelöscht!", severity: "success" })
            const newArray = (insuranceData === undefined) ? "" : insuranceData.filter(insurance => insurance.id !== insuranceId);
            setInsuranceData(newArray)
            console.log(insuranceId)
            closeDeleteConfirmationDialog();
        }else{
            console.log("You have no permission.")
        }   
    };
//END OF DELETE PATTERNS

    const columns: GridColDef[] = [
        {
            field: 'insuranceNumber',
            headerName: 'Nr.',
            valueGetter: (params) => params.row.id,
            width: 70
        },
        {
            field: 'telefon',
            headerName: 'Telefon',
            valueGetter: (params) => params.row.telefon,
            width: 200
        },
        {
            field: 'email',
            headerName: 'Email',
            valueGetter: (params) => params.row.email,
            width: 350
        },
        {
            field: 'insurance_name',
            headerName: 'Pflegekasse',
            valueGetter: (params) => params.row.insurance_name,
            width: 350
        },
        { 
            field: 'action', 
            headerName: 'Aktionen', 
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return (
                    <> {(is_superuser == "true" || true) && <>
                        <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                            <Delete />
                        </IconButton>
                        <Dialog
                            open={openDeleteDialog && insuranceIdToDelete === params.row.id}
                            onClose={closeDeleteConfirmationDialog}
                        >
                            <DialogTitle>Bestätigung Löschung</DialogTitle>
                            <DialogContent>
                                Sind Sie sicher, dass Sie "{params.row["insurance_name"]}" löschen wollen?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                    Abbruch
                                </Button>
                                <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                    Bestätigen
                                </Button>
                            </DialogActions>
                        </Dialog></>}
                    </>
                );
            },
            flex: 1
        },

    ]

    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = insuranceData?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditInsurance(getEmptyInsurance())
        } else {
            setEditInsurance(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditInsurance(getEmptyInsurance());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject: IInsurance) => {
        if (insuranceData !== undefined) {
            setFeedback({open: true, message: "Gespeichert!", severity: "success"})

            let testObject = insuranceData.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setInsuranceData([
                    ...insuranceData,
                    savedObject
                ])
            } else {
                setInsuranceData([
                    ...insuranceData.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }


    const handleSave = () => {
        setWasSuccessfully(true);

        if (editInsurance.id === 0) {
            uploadFetch("/insurance/", true, editInsurance, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading)
        } else {

            uploadFetch(`/insurance/${editInsurance.id}`, false, editInsurance, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({open: true, message: "Ein fehler ist aufgetretten!", severity: "error"})
            setIsLoading(false);
        }
    }, [wasSuccessfully])

    const handleSearchTerm = () => {
        if (insuranceData === undefined) {
            return [];
        } else if (searchTerm === "") {
            return insuranceData
        } else {
            return insuranceData.filter(x =>
                String(x.insurance_name).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.telefon).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.info).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.email).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }

    if (
        (statusCode !== null && statusCode !== 200)
    ) {
        return <Alert severity="error">Fehler!</Alert>
    } else if (
        insuranceData === undefined
    ) {
        return <LinearProgress/>
    } else {
        return (
            <>
                <Typography variant="h5">
                    Pflegekassenverzeichnis
                  {true && <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Pflegekasse hinzufügen</Button> }
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={(isOpen) ? 6 : 12}>
                        <Box sx={{ height: '80vh', overflow: 'auto' }}>
                            <DataGrid
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                rows={handleSearchTerm()}
                                columns={columns}
                                //getRowId={(row) => row.id}
                                onRowClick={(params, event, details) => {
                                    if(true){
                                    const target = event.target as HTMLElement;
                                    const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                                    if (isDeleteIconClicked) {
                                        event.stopPropagation();
                                        openDeleteConfirmationDialog(Number(params.id));
                                    } else {
                                        handleChangeId(params)
                                    }
                                }}}
                                autoHeight
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={(isOpen) ? 6 : 0}>
                        <Collapse in={isOpen}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6">
                                    Versicherung
                                    <Button sx={{float: "right", ml: 2}} type="submit" variant="contained"
                                            disabled={isLoadgin}>Speichern</Button>
                                    <Button sx={{float: "right"}} onClick={() => setIsOpen(false)}
                                            variant="outlined">Abbruch</Button>
                                </Typography>
                                <InsuranceEdit
                                    key={`editInsurance-${editInsurance.id}`}
                                    addressTypeData={addressTypeData || []}
                                    insuranceObject={editInsurance}
                                    setInsuranceObject={setEditInsurance}
                                />
                            </form>
                        </Collapse>
                    </Grid>
                </Grid>
            </>
        )
    }


}