import { Box, Button, Typography } from "@mui/material";
import { QueryObserverResult } from "@tanstack/react-query";
import React from "react";

interface IProps {
    refetchFunction: Function;
}


export const ErrorTryRefetch:React.FC<IProps> = ({refetchFunction}) => {
    return(
        <Box
          sx={{
            minHeight: '50vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Typography variant='h6'>
            Fehler beim Laden der Daten. Bitte versuchen Sie es erneut.
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              refetchFunction();
            }}
          >
            Erneut versuchen
          </Button>
        </Box>
    )
} 