import React from "react";
import { IProject } from "../../interfaces/IProject";
import { useGetLogByProjecId } from "../../hooks/data/data_project";
import { CustomCircularProgress } from "../core/CustomCircularProgress";
import { ErrorTryRefetch } from "../core/ErrorTryRefetch";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";


interface IProps {
    project: IProject
}


export const ProjectLogTab:React.FC<IProps> = ({project}) => {
    const { logArray, isPendingLogArray, refetechLogArrayByProjectId } = useGetLogByProjecId(project.id);

    if (isPendingLogArray) {
        return <CustomCircularProgress/>
    }

    if (!logArray) {
        return <ErrorTryRefetch refetchFunction={refetechLogArrayByProjectId} />
    }

    return(
        <>
            <Typography variant="h5">
                Historie
            </Typography>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width={170}>Datum</TableCell>
                        <TableCell width={200}>Benutzer</TableCell>
                        <TableCell width={150}>Sektion</TableCell>
                        <TableCell>Meldung</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logArray.map(log =>
                        <TableRow>
                            <TableCell>{new Date(log.created_at).toLocaleString()}</TableCell>
                            <TableCell>{log.first_name} {log.last_name}</TableCell>
                            <TableCell>{log.projectLogType}</TableCell>
                            <TableCell>{log.log}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    )
}