import { Box, Card, CardContent, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { IOffer } from "../../interfaces/IOffer";
import { IOfferPosition } from "../../interfaces/IOfferPosition";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { IOfferType } from "../../interfaces/IOfferType";
import { IOfferState } from "../../interfaces/IOfferState";
import { IProject } from "../../interfaces/IProject";
import { IUserSmall } from "../../interfaces/IUser";
import { IProjectState } from "../../interfaces/IProjectState";
import { SalesBoardUser } from "./SalesBoardUser";
import { useGetLogStatistic } from "../../hooks/data/data_project";

export interface IChartData {
    name: string;
    value: number;
}

interface IProps {
    userSmallArray: IUserSmall[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF8042', '#FF8042', '#FF8042', '#FF8042', '#FF8042'];

export const SalesDasboard: React.FC<IProps> = ({ userSmallArray }) => {
    const {logArray, refetechLogArrayByProjectId, isPendingLogArray} = useGetLogStatistic();

    const [fromDate, setFromDate] = useState<Date | null>(() => {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1,3);
    });
    const [toDate, setToDate] = useState<Date | null>(() => {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0,3);
    });
    const [selectedUser, setSelectedUser] = useState([
        Number(localStorage.getItem("idUser"))
    ])

    const [projectStateArray,setProjectStateArray,statusCodeProjectState] = useFetch<IProjectState[]>("/project/state");
    const [projectSmallArray, setProjectSmallArray, statusCodeProjectArray] = useFetch<IProject[]>("/project/small/");

    const [offerArray, setOfferArray, statusCode] = useFetch<IOffer[]>("/offer/simple/");
    const [offerPosArray, setOfferPosArray, statusPosCode] = useFetch<IOfferPosition[]>("/offer/position/");
    const [offerStateArray, setOfferStateArray, statusStateCode] = useFetch<IOfferState[]>("/offer/state/");
    const [dataArray, setDataArray] = useState<IChartData[]>([]);

    useEffect(() => {
        if (offerArray !== undefined && offerStateArray !== undefined) {
            let tmpArray: IChartData[] = [];
            offerStateArray.map(state => {
                tmpArray.push({
                    name: state.offerState,
                    value: offerArray.filter(x => x.OfferState === state.id).length + 1
                } as IChartData)
            })
            setDataArray(tmpArray);

        }
    }, [offerArray, offerStateArray])

    useEffect(() => {
        console.log("----")
        console.log(fromDate)
    },[fromDate])

    if (offerArray === undefined || offerPosArray === undefined || offerStateArray === undefined) {
        return <>Bitte warten...</>
    }

    return (
        <>
            <Typography variant="h5">Vertrieb</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        label="Von"
                        value={(fromDate === null) ? "" : fromDate.toISOString().split("T")[0]}
                        type="date"
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => 
                            setFromDate(event.target.value === "" ? null : new Date(event.target.value))
                        }
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Bis"
                        value={(toDate === null) ? "" : toDate.toISOString().split("T")[0]}
                        type="date"
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => 
                            setToDate(event.target.value === "" ? null : new Date(event.target.value))
                        }
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id='folder-label' shrink>
                            Mitarbeiter
                        </InputLabel>
                        <Select
                            labelId='folder-label'
                            placeholder='Mitarbeiter auswählen'
                            id='folder'
                            multiple
                            notched
                            label='Mitarbeiter'
                            value={selectedUser}
                            onChange={e => {
                                const value = e.target.value;

                                if (value && typeof value !== 'string') {
                                    setSelectedUser(value);
                                }
                            }}
                            renderValue={selectedValues =>
                                selectedValues
                                .map( selectedValue =>{
                                    let user = userSmallArray.find(user => user.id === selectedValue); 
                                    if (user === undefined) {
                                        return "ERROR"
                                    } else {
                                        return `${user.first_name} ${user.last_name}`
                                    }
                                })
                                .join(', ')
                            }
                            size='small'
                            fullWidth
                        >
                        {userSmallArray
                            .filter(user => user.profile !== null)
                            .filter(user => [1,3,4,5,6].indexOf(user.profile!.Role) !== -1)
                            .map(user => (
                                <MenuItem
                                    key={`user-${user.id}`}
                                    value={user.id}
                                >
                                    <Checkbox
                                        checked={selectedUser.includes(
                                            user.id
                                        )}
                                    />
                                    <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                
                    {/*
                    <Grid item xs={12} lg={6} xl={4}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Angbote nach Status</Typography>

                                <Box sx={{ height: 300, mt: 5 }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                data={dataArray}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={true}
                                                label={(a: any) => { console.log(a); return `${a["name"]} (${a["value"]})` }}
                                                outerRadius={150}
                                                fill="#4cb1ad"
                                                dataKey="value"
                                            />
                                            {dataArray.map((x, i) =>
                                                <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                                            )}
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>
                     */}

                
                <SalesBoardUser
                    key={`salesboard-user-all`}
                    logArray={logArray || []}
                    projectStateArray={projectStateArray || []}
                    projectSmallArray={projectSmallArray || []}
                    fromDate={fromDate}
                    toDate={toDate}
                />
                {userSmallArray
                    .filter(user => user.profile !== null)
                    .filter(user => selectedUser.indexOf(user.id) !== -1)
                    .map(user =>
                        <SalesBoardUser
                            key={`salesboard-user${user.id}`}
                            currentUserSamll={user}
                            logArray={logArray || []}
                            projectStateArray={projectStateArray || []}
                            projectSmallArray={projectSmallArray || []}
                            fromDate={fromDate}
                            toDate={toDate}
                        />
                    )}
            </Grid>
            <Box sx={{ mb: 10 }} />
        </>
    )
}