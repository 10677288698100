import { useMutation, useQuery } from "@tanstack/react-query";
import { IContractType } from "../../interfaces/IContractType";
import { customFetcher } from "../useFetch";
import { IContractState } from "../../interfaces/IContractState";
import { IContract } from "../../interfaces/IContract";
import { ICreateContractBulk } from "../../components/Contract/Childs/AddContracts";
import { ISignedContract } from "../../components/Contract/Childs/SignedContract";

export const useUpdateContact = () => {
  const {
    mutateAsync: updateContract,
    isPending: isPendingUpdateContract,
  } = useMutation({
    mutationFn: (contractObject: IContract) =>
      customFetcher({
        endpoint: `/contract/${contractObject.id}`,
        method: 'PUT',
        body: contractObject,
      }),
  });

  return {
    updateContract,
    isPendingUpdateContract,
  };
};


export const useCreateContractBulk = () => {
    const {
      mutateAsync: createContractBulk,
      isPending: isPendingCreateContractBulk,
    } = useMutation({
      mutationFn: (contractBulk: ICreateContractBulk) =>
        customFetcher({
          endpoint: '/contract/bulk/',
          method: 'POST',
          body: contractBulk,
        }),
    });
  
    return {
        createContractBulk,
        isPendingCreateContractBulk,
    };
  };

  export const useCreateSignedContract = () => {
    const {
      mutateAsync: createSignedContract,
      isPending: isPendingCreateSignedContract,
    } = useMutation({
      mutationFn: (contractBulk: ISignedContract) =>
        customFetcher({
          endpoint: '/contract/document/',
          method: 'POST',
          body: contractBulk,
        }),
    });
  
    return {
      createSignedContract,
        isPendingCreateSignedContract,
    };
  };


  export const useGenerateContractDocument = () => {
    const {
      mutateAsync: generateContractDocument,
      isPending: isPendingGenerateContractDocument,
    } = useMutation({
      mutationFn: (idContract: number) =>
        customFetcher({
          endpoint: `/contract/generate_pdf/${idContract}`,
          method: 'GET',
        }),
    });
  
    return {
      generateContractDocument,
      isPendingGenerateContractDocument,
    };
  };

export const useGetContract = (idProject:number) => {
    const {
      data: contractArray,
      isLoading: isLoadingContractArray,
      refetch: refetchContractArray,
    } = useQuery<IContract[]>({
      queryKey: ['contract/byProject/',idProject],
      queryFn: () => customFetcher({ endpoint: `/contract/byProject/${idProject}` }),
    });
    return { contractArray, isLoadingContractArray, refetchContractArray};
  };

export const useGetContractType = () => {
    const {
      data: contractTypeArray,
      isLoading: isLoadingContractType,
      refetch: refetchContractType,
    } = useQuery<IContractType[]>({
      queryKey: ['contract/type/'],
      queryFn: () => customFetcher({ endpoint: '/contract/type/' }),
    });
    return { contractTypeArray, isLoadingContractType, refetchContractType};
  };

export const useGetContractState = () => {
    const {
        data: contractStateArray,
        isLoading: isLoadingContractState,
        refetch: refetchContractState,
    } = useQuery<IContractState[]>({
        queryKey: ['contract/state/'],
        queryFn: () => customFetcher({ endpoint: '/contract/state/' }),
    });
    return { contractStateArray, isLoadingContractState, refetchContractState};
};