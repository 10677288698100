import React, { Dispatch, useEffect, useState } from "react";
import { IContract } from "../../../interfaces/IContract";
import { IContractPosition } from "../../../interfaces/IContractPosition";
import { IContractType } from "../../../interfaces/IContractType";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { Delete } from "@mui/icons-material";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { IService } from "../../../interfaces/IService";
import { IUnit } from "../../../interfaces/IUnit";


interface IProps {
    index: number;
    contract: IContract;
    contractType: IContractType;
    totalValue: number;
    contractPosition: IContractPosition;
    setContractPositionArray: Dispatch<React.SetStateAction<IContractPosition[]>>;
    serviceArray: IService[];
    unitArray: IUnit[];
}


export const EditorContractPosition:React.FC<IProps> = ({
    index,
    contract,
    contractType,
    totalValue,
    contractPosition,
    setContractPositionArray,
    serviceArray,
    unitArray
}) => {
    const [editContractPosition,setEditContractPosition] = useState(contractPosition);
    const is_superuser = localStorage.getItem("is_superuser") === "true"
    const isDisabled = (contractType.isEditJustAdmin && !is_superuser) || (contract.ContractState >= 30);


    const calcTotalPrice = () => {
        let totalPrice = Number(editContractPosition.price) * editContractPosition.units;
        return totalPrice.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
    }



    useEffect(() => {
        setContractPositionArray(prev => [
            ...prev.map(x => x.id === editContractPosition.id ? editContractPosition : x)
        ])
    },[editContractPosition])

    const handleRemove = () => {
        setContractPositionArray(prev => [
            ...prev.filter(x => x.id !== editContractPosition.id)
        ])
    }


    if (contractType.id === 20) {
        return(
            <>
                <TableRow>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>
                        <CustomeTextField
                            attr="title"
                            label="Titel"
                            object={editContractPosition}
                            setObject={setEditContractPosition}
                            type="string"
                            disabled={isDisabled}
                            required
                        />
                    </TableCell>
                    <TableCell>
                        <CustomeTextField
                            attr="units"
                            label="Abzahlung"
                            object={{...editContractPosition, units: (editContractPosition.units*100)}}
                            setObject={(updatedConPos:IContractPosition) => setEditContractPosition({
                                ...updatedConPos,
                                units : (updatedConPos.units/100)
                            })}
                            type="number"
                            disabled={isDisabled}
                            required
                        />
                    </TableCell>
                    <TableCell>
                        <Box sx={{float: "right"}}>
                            {(totalValue*editContractPosition.units).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                        </Box>
                    </TableCell>
                    <TableCell>
                        <IconButton disabled={isDisabled} onClick={handleRemove}><Delete/></IconButton>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    return(
        <>
            <TableRow>
                <TableCell>
                </TableCell>

                <TableCell sx={{p: 0}}>{editContractPosition.articelNumber}</TableCell>


                {/*
                <TableCell>
                    <CustomeTextField
                        label="Partner"
                        attr="Partner"
                        object={editOfferPosition}
                        setObject={setEditOfferPosition}
                        type="select"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                    >
                        {
                            partnerArray
                                .filter(x => offerPartnerArray.map(x => x.Partner).indexOf(x.id) > -1)
                                .map(x =>
                                    <MenuItem key={`partner-${x.id}`} value={x.id}>
                                        {x.Contact_Person.Company?.company_name}
                                    </MenuItem>
                                )
                        }
                    </CustomeTextField>
                </TableCell>
                 */}



                <TableCell >
                    <CustomeTextField
                        label="Position"
                        attr="title"
                        object={editContractPosition}
                        setObject={setEditContractPosition}
                        type="string"
                        size="small"
                        required
                        disabled={isDisabled}
                        //rows={3}
                    />
                </TableCell>


                <TableCell >
                </TableCell>


                <TableCell >
                    <CustomeTextField
                        label="Zeitfaktor"
                        attr="timeFactor"
                        object={editContractPosition}
                        setObject={setEditContractPosition}
                        type="number"
                        size="small"
                        required
                        disabled={isDisabled}
                    />
                </TableCell>

                {/*
                <TableCell >
                    <CustomeTextField
                        label="Stundenpreis"
                        attr="price_per_hour"
                        object={editOfferPosition}
                        setObject={(editedObject:IOfferPosition) => setEditOfferPosition({
                            ...editedObject,
                            wasChangedPricePerHour: true
                        })}
                        type="number"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                    />
                </TableCell>
                 */}



                <TableCell >
                    <CustomeTextField
                        label="Einheiten"
                        attr="units"
                        object={editContractPosition}
                        setObject={setEditContractPosition}
                        type="number"
                        size="small"
                        required
                        disabled={isDisabled}
                    />
                </TableCell>


                <TableCell >{unitArray.find(x => x.id === editContractPosition.Unit)?.unit}</TableCell>

                <TableCell >
                    <CustomeTextField
                        label="Preis"
                        attr="price"
                        object={editContractPosition}
                        setObject={setEditContractPosition}
                        type="euro"
                        size="small"
                        required
                        disabled={isDisabled}
                    />
                </TableCell>

                {/*<TableCell>{calcPrice()}</TableCell>*/}

                <TableCell >{calcTotalPrice()}</TableCell>

                <TableCell >
                    <IconButton disabled={isDisabled}><Delete onClick={handleRemove}/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}
