import {Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, TextField, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowParams, deDE} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {CustomSnackbar} from '../../components/core/Snackbar';
import {uploadFetch, useFetch, deleteFetch} from "../../hooks/useFetch";

//interfaces imports
import {IFeedback} from "../../interfaces/IFeedback";
import {IPartner} from "../../interfaces/IPartner";
import {IPropertyManagment} from "../../interfaces/IPropertyManagement";
import {PropertyManagmentEdit} from "../../components/propertymanagment/PropertyManagmentEdit";
import {IGender} from "../../interfaces/IGender";
import {ITitle} from "../../interfaces/ITitle";
import {getEmptyCompany} from "../../components/company/CompanyEdit";
import {getEmptyCustomer} from "../../components/customer/CustomerEdit";
import { Delete } from "@mui/icons-material";
import { usePermissions } from "../../contexts/permissions/PermissionContext";

export const getEmptyPropertyManagement = () => {
    return {
        id: 0,
        property_management_name: "",
        homepage: "",
        telefon: "",
        email: "",
        Company: getEmptyCompany(),
        Contact_Person: getEmptyCustomer(true)
    } as IPropertyManagment
}


export const PropertyManagementOverview: React.FC = () => {
    const [propertyManagementData, setpropertyManagementData, statusCode] = useFetch<IPropertyManagment[]>("/property_management/");
    //
    const [genderData, setGenderData, statusCodeGender] = useFetch<IGender[]>("/customer/gender/");
    const [titleData, setTitleData, statusCodeTitle] = useFetch<ITitle[]>("/customer/academic-title/");

    const [editPropertyManagement, setEditPropertyManagement] = useState(getEmptyPropertyManagement());
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [isLoadgin, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    //
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)

    const [searchTerm, setSearchTerm] = useState("");

    //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [propertyManagementIdToDelete, setPropertyManagementIdToDelete] = React.useState<number | null>(null);
    const is_superuser = localStorage.getItem("is_superuser")
    //const { permissions } = usePermissions();
    
    const openDeleteConfirmationDialog = (propertyManagementId: number) => {
        setPropertyManagementIdToDelete(propertyManagementId);
        setOpenDeleteDialog(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setPropertyManagementIdToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleRemove = (propertyManagementId: number) => {
        if (is_superuser === "true"){
            deleteFetch("/property_management/", propertyManagementId,setWasSuccessfully)
            setFeedback({ open: true,message: "Hausverwaltung erfolgreich gelöscht!", severity: "success" })
            const newArray = (propertyManagementData === undefined) ? "" : propertyManagementData.filter(propertyManagement => propertyManagement.id !== propertyManagementId);
            setpropertyManagementData(newArray)
            console.log(propertyManagementId)
            closeDeleteConfirmationDialog();
        }else{
            console.log("You have no permission.")
        }   
    };
//END OF DELETE PATTERNS

    const columns: GridColDef[] = [
        {
            field: 'propertyManagementId',
            headerName: 'Nr.',
            valueGetter: (params) => params.row.id,
            width: 70
        },

        {
            field: 'telefon',
            headerName: 'Telefon',
            valueGetter: (params) => params.row.Contact_Person.telefon,
            width: 200
        },
        {
            field: 'email',
            headerName: 'E-Mail',
            valueGetter: (params) => params.row.Contact_Person.email,
            width: 350
        },

        {
            field: 'Company',
            headerName: 'Firma',
            valueGetter: (params) => params.row.Contact_Person.Company.company_name,
            width: 350
        },
        { 
            field: 'action', 
            headerName: 'Aktionen', 
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return (
                    <> {(is_superuser == "true" || true) && <>
                        <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                            <Delete />
                        </IconButton>
                        <Dialog
                            open={openDeleteDialog && propertyManagementIdToDelete === params.row.id}
                            onClose={closeDeleteConfirmationDialog}
                        >
                            <DialogTitle>Bestätigung Löschung</DialogTitle>
                            <DialogContent>
                                Sind Sie sicher, dass Sie "{params.row.Contact_Person.Company.company_name}" löschen wollen?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                    Abbruch
                                </Button>
                                <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                    Bestätigen
                                </Button>
                            </DialogActions>
                        </Dialog></>}
                    </>
                );
            },
            flex: 1
        },



    ]
    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = propertyManagementData?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditPropertyManagement(getEmptyPropertyManagement())
        } else {
            setEditPropertyManagement(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditPropertyManagement(getEmptyPropertyManagement());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject: IPropertyManagment) => {
        if (propertyManagementData !== undefined) {
            setFeedback({open: true, message: "Gespeichert!", severity: "success"})

            let testObject = propertyManagementData.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setpropertyManagementData([
                    ...propertyManagementData,
                    savedObject
                ])
            } else {
                setpropertyManagementData([
                    ...propertyManagementData.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }


    const handleSave = () => {
        setWasSuccessfully(true);
        if (editPropertyManagement.id === 0) {
            uploadFetch("/property_management/", true, editPropertyManagement, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading)
        } else {

            uploadFetch(`/property_management/${editPropertyManagement.id}`, false, editPropertyManagement, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({open: true, message: "Ein fehler ist aufgetretten!", severity: "error"})
            setIsLoading(false);
        }
    }, [wasSuccessfully])

    const handleSearchTerm = () => {
        if (propertyManagementData === undefined) {
            return [];
        } else if (searchTerm === "") {
            return propertyManagementData
        } else {
            return propertyManagementData.filter(x =>
                String(x.Contact_Person.email).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.Contact_Person.Company?.company_name).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.Contact_Person.telefon).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }

    async function deletePartner(id: number) {
        try {
            deleteFetch(`/partner/`, id, setWasSuccessfully);
            setpropertyManagementData((prevState: IPartner[]) => {
                return prevState.filter((cust: IPartner) => cust.id !== id);
            });
            setFeedback({open: true, message: 'Kunde erfolgreich gelöscht.', severity: 'success'});
        } catch (error) {
            console.error("There was an error fetching the customer data:", error);
        }
    }


    if (
        (statusCode !== null && statusCode !== 200)
        || (statusCodeGender !== null && statusCodeGender !== 200)
        || (statusCodeTitle !== null && statusCodeTitle !== 200)
    ) {
        return <Alert severity="error">Fehler!</Alert>
    } else if (
        propertyManagementData === undefined
        || genderData === undefined
        || titleData === undefined
    ) {
        return <LinearProgress/>
    } else {
        return (
            <>
                <Typography variant="h5">
                    Hausverwaltungsverzeichnis
                    {true && <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Hausverwaltung hinzufügen</Button> }
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={(isOpen) ? 6 : 12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={handleSearchTerm()}
                            columns={columns}
                            //getRowId={(row) => row.id}
                            onRowClick={(params, event, details) => {
                                if(true){
                                    const target = event.target as HTMLElement;
                                const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                                if (isDeleteIconClicked) {
                                    event.stopPropagation();
                                    openDeleteConfirmationDialog(Number(params.id));
                                } else {
                                    handleChangeId(params)
                                }}
                                }  
                            }
                            autoHeight
                        />
                    </Grid>
                    <Grid item sm={(isOpen) ? 6 : 0}>
                        <Collapse in={isOpen}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6">
                                    Hausverwaltung
                                    <Button sx={{float: "right", ml: 2}} type="submit" variant="contained"
                                            disabled={isLoadgin}>Speichern</Button>
                                    <Button sx={{float: "right"}} onClick={() => setIsOpen(false)}
                                            variant="outlined">Abbruch</Button>
                                </Typography>
                                <PropertyManagmentEdit
                                    key={`editPropertyManagement-${editPropertyManagement.id}`}
                                    propertyManagementObject={editPropertyManagement}
                                    setpropertyManagementObject={setEditPropertyManagement}
                                    genderArray={genderData}
                                    titleArray={titleData}
                                />
                            </form>
                        </Collapse>
                    </Grid>
                </Grid>
            </>
        )
    }

}