import { useQuery } from "@tanstack/react-query";
import { IReasonCancellation } from "../../interfaces/IReasonCancellation";
import { customFetcher } from "../useFetch";

export const useGetReasonCancellation = () => {
    const {
      data: reasonCancellationArray,
      isLoading: isLoadingReasonCancellationArray,
      refetch: refetchReasonCancellationArray,
    } = useQuery<IReasonCancellation[]>({
      queryKey: ['generic/reason_cancellation/'],
      queryFn: () => customFetcher({ endpoint: '/generic/reason_cancellation/' }),
    });
    return { reasonCancellationArray, isLoadingReasonCancellationArray, refetchReasonCancellationArray};
  };