import React, { useState } from "react";
import { IContract } from "../../../interfaces/IContract";
import { IContractState } from "../../../interfaces/IContractState";
import { IContractType } from "../../../interfaces/IContractType";
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { EditorContractPosition } from "./EditorContractPosition";
import { getTmpId } from "../../core/generic_helpders";
import { useUpdateContact } from "../../../hooks/data/data_contract";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";
import { IService } from "../../../interfaces/IService";
import { IUnit } from "../../../interfaces/IUnit";
import { IServiceCategory } from "../../../interfaces/IServiceCategory";
import { OfferPositionAdd } from "../../offer/Childs/OfferPositionAdd";
import { getEmptyOffer } from "../../offer/OfferTable";
import { IProject } from "../../../interfaces/IProject";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";
import { IContractPosition } from "../../../interfaces/IContractPosition";
import { IOfferPartner } from "../../../interfaces/IOfferPartner";


interface IProps {
    project: IProject;
    orgContract: IContract;
    editContract: IContract;
    setEditContract: Function;
    contractStateArray: IContractState[];
    contractTypeArray: IContractType[];
    serviceCategoryArray: IServiceCategory[];
    serviceArray: IService[];
    unitArray: IUnit[];
    handleClose: () => void;
    handleSave: () => void;
}


export const EditorContract:React.FC<IProps> = ({
    project,
    orgContract,
    editContract,
    setEditContract,
    contractStateArray,
    contractTypeArray,
    serviceCategoryArray,
    serviceArray,
    unitArray,
    handleClose,
    handleSave
}) => {
    const {updateContract, isPendingUpdateContract} = useUpdateContact();
    const [contractPositions,setContractPositions] = useState(editContract.ContactPositions);
    const [totalValue,setTotalValue] = useState(contractPositions.map(x => x.price).reduce((a,b) => a+b,0));

    const [isOpenOfferPost,setIsOpenOfferPost] = useState(false);
    
    const currentContractType = contractTypeArray.find(x => x.id === editContract.ContractType);
    const is_superuser = localStorage.getItem("is_superuser") === "true"
    const isDisabled = (currentContractType === undefined) ||  (currentContractType.isEditJustAdmin && !is_superuser);
    //
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)

    const castOfferPosToContractPos = (offerPositionArray:IOfferPosition[]) => {
        let tmpArray:IContractPosition[] = [];
        let tmpId = getTmpId(contractPositions);
        console.log("offerPositionArray");
        console.log(offerPositionArray)
        offerPositionArray.map((offerPos,idx) => tmpArray.push({
            id: tmpId-idx,
            articelNumber: offerPos.articelNumber,
            Contract: editContract.id,
            description: offerPos.description,
            position: 0,
            price: offerPos.price,
            price_per_hour: offerPos.price_per_hour,
            Service: offerPos.Service,
            timeFactor: offerPos.timeFactor,
            title: offerPos.title,
            Unit: offerPos.Unit,
            units: offerPos.units
        }))
        console.log("tmpArray");
        console.log(tmpArray)
        setContractPositions(prev => [
            ...prev,
            ...tmpArray
        ])
        setIsOpenOfferPost(false);
    }

    const handleNew = () => {
        if (currentContractType?.id === 20) {
            setContractPositions(prev => [
                ...prev,
                {
                    articelNumber: String(contractPositions.length+1),
                    Contract: editContract.id,
                    description: "",
                    id: getTmpId(contractPositions),
                    position: contractPositions.length+1,
                    price: 0,
                    price_per_hour: 0,
                    Service: null,
                    timeFactor: 0,
                    title: "",
                    Unit: 9,
                    units: 0
                }
            ])
        } else {
            setIsOpenOfferPost(true);
        }
    }

    const handleUpload = async () => {
        let uploadObject:IContract = {
            ...editContract,
            ContactPositions: contractPositions
        }
        try {
            await updateContract(uploadObject);
            setFeedback({ open: true,message: "Erfolgreich gespeichert!", severity: "success" })
            handleSave();
            handleClose();
        } catch (error) {
            console.error(error);
            setFeedback({ open: true,message: "Es ist ein Fehler aufgeretten!", severity: "error" })
        }
    }


    if (!currentContractType) {
        return <>Fehler!</>
    }

    return(
        <>
        <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

        <Dialog open={isOpenOfferPost} onClose={setIsOpenOfferPost} maxWidth="md" fullWidth>
            <DialogTitle style={{background: "white", border: "10px solid white"}} >Neue Position(en) hinzufügen</DialogTitle>
            <DialogContent >
                <OfferPositionAdd
                    offerObject={getEmptyOffer(project)}
                    offerPartnerArray={[
                        {
                            id: -1,
                            Offer: -1,
                            Partner: null,
                            selling_price_offer: 80
                        } as IOfferPartner
                    ]}
                    partnerArray={[]}
                    offerPositionArray={[]}
                    setOfferPosition={castOfferPosToContractPos}
                    serviceArray={serviceArray}
                    serviceCategoryArray={serviceCategoryArray}
                    setIsOpenAdd={setIsOpenOfferPost}
                />
            </DialogContent>
        </Dialog>

        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomeTextField
                        attr="title"
                        label="Titel"
                        object={editContract}
                        setObject={setEditContract}
                        type="string"
                        disabled={orgContract.ContractState >= 30}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomeTextField
                        attr="contractType"
                        label="Vertragsart"
                        object={currentContractType}
                        setObject={() => {}}
                        type="string"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomeTextField
                        attr="ContractState"
                        label="Vertragsstatus"
                        object={editContract}
                        setObject={setEditContract}
                        type="select"
                        disabled={orgContract.ContractState >= 30}
                    >
                        {contractStateArray.map(state =>
                            <MenuItem key={`state-${state.id}`} value={state.id}>
                                {state.contractState}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
            </Grid>

            <Collapse in={orgContract.ContractState >= 30} sx={{mt: 1}}>
                <Alert severity="warning">
                    Dieser Vertrag wurde bereits angenommen und kann nichtmehr verändert werden!
                </Alert>
            </Collapse>

            <Typography sx={{mt: 5}}variant="h5">Positionen</Typography>
            <Table>
                <TableHead>
                    {
                    (currentContractType.id === 20) ? 
                        <TableRow>
                            <TableCell width={10}>Nr.</TableCell>
                            <TableCell>Titel</TableCell>
                            <TableCell width={150}>Abzahlung in %</TableCell>
                            <TableCell width={150}>Wert</TableCell>
                            <TableCell width={10}></TableCell>
                        </TableRow>
                    :
                        <TableRow>
                            <TableCell sx={{ width: 50}}></TableCell>
                            <TableCell sx={{ width: 80}}>Artk.Nr.</TableCell>
                            {/* <TableCell sx={{ width: 150}}>Partner</TableCell> */}
                            <TableCell sx={{minWidth: 300}}>Position</TableCell>
                            <TableCell sx={{ width: 50}}></TableCell>
                            <TableCell sx={{ width: 150}}>Faktor</TableCell>
                            {/*
                                <TableCell sx={{ width: 150}}>P.p.S.</TableCell>
                            */}
                            
                            <TableCell sx={{ width: 150}}>Einheiten</TableCell>
                            <TableCell sx={{ width: 50}}>Einheit</TableCell>
                            <TableCell sx={{ width: 150}}>Preis</TableCell>
                            <TableCell sx={{ width: 50}}>Total</TableCell>
                            <TableCell sx={{ width: 50}}></TableCell>
                        </TableRow>

                    }  
                </TableHead>
                <TableBody>
                    {contractPositions.map((contractPos,idx) =>
                        <EditorContractPosition
                            key={`contractPosition-${contractPos.id}`}
                            index={idx}
                            contract={orgContract}
                            contractPosition={contractPos}
                            totalValue={totalValue}
                            contractType={currentContractType}
                            setContractPositionArray={setContractPositions}
                            serviceArray={serviceArray}
                            unitArray={unitArray}
                        />
                    )}
                    {(currentContractType.id === 20) ?
                        <TableRow>
                            <TableCell colSpan={2}></TableCell>
                            <TableCell>
                                {(contractPositions.map(x => x.units).reduce((a,b) => a+b,0)*100).toFixed(2)} % 
                            </TableCell>
                            <TableCell colSpan={2}>
                                <CustomeTextField
                                    attr="price"
                                    label="Total"
                                    object={{price: totalValue}}
                                    setObject={(prev:{price:number}) => setTotalValue(prev.price)}
                                    type="euro"
                                    disabled={isDisabled}
                                    required
                                />
                            </TableCell>
                        </TableRow>
                        :
                        <>
                            <TableRow>
                                <TableCell colSpan={6} />
                                <TableCell colSpan={2}><b>Summe</b></TableCell>
                                <TableCell>{totalValue.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                                <TableCell/>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={6} />
                                <TableCell sx={{borderBottom: "solid 1px"}} colSpan={2}><b>MwSt.</b></TableCell>
                                <TableCell sx={{borderBottom: "solid 1px"}}>{(totalValue*0.19).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                                <TableCell/>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={6} />
                                <TableCell sx={{borderBottom: "solid 1px"}} colSpan={2}><b>Total</b></TableCell>
                                <TableCell sx={{borderBottom: "solid 1px"}}>{((totalValue*0.19)+totalValue).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                                <TableCell/>
                            </TableRow>
                        </>
                    }
                </TableBody>
            </Table>

                    <Box sx={{width: 140, height: 60, float: "right"}} style={{
                                position: 'sticky',
                                bottom: 30,
                                right: 0,
                                zIndex: 100,
                                padding: 10,
                                background: "white",
                                border: "10px solid white"
                            }} >
                        <Button style={{
                            position: 'absolute',
                            bottom: 1,
                            right: 175}} sx={{float: "right", width: 150}} variant="contained" onClick={handleNew}
                            disabled={orgContract.ContractState >= 30}
                        >
                            Neue Position
                        </Button>

                        <Button
                            sx={{
                                position: 'absolute',
                                bottom: 1,
                                right: 5,
                                float: "right"
                            }} 
                            variant='contained'
                            disabled={isPendingUpdateContract || orgContract.ContractState >= 30}
                            onClick={handleUpload}
                        >
                            {isPendingUpdateContract ? (
                            <Box display='flex' alignItems='center' gap={1}>
                                <span>Speichern</span>
                                <CircularProgress size={20} />
                            </Box>
                            ) : (
                                'Speichern'
                            )}
                        </Button>

                        </Box>
                    
                 </Box>

        </>
    )
}