import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { CustomerOverview } from "../../sites/CustomerManagment/CustomerOverview";
import { NavBar } from "./NavBar";
import { ServiceOverview } from "../../sites/ServiceManagement/ServiceOverview";
import { InsuranceOverview } from "../../sites/InsuranceManagement/InsuranceOverview";
import { ProjectOverview } from "../../sites/ProjectManagement/ProjectOverview";
import { PartnerOverview } from "../../sites/PartnerManagement/PartnerOverview";
import { PropertyManagementOverview } from "../../sites/PropertyManagement/PropertyManagementOverview";
import { RoleManagementOverview } from "../../sites/RoleManagement/RoleManagementOverview";
import ProtectedRoute from "./ProtectedRoute";
import { NoPermission } from "../../sites/NoPermission";
import { MaterialOverview } from "../../sites/MaterialManagment/MaterialOverview";
//import { Template } from "../../sites/TemplateManagement/Template";
import { DashboardOverview } from "../../sites/Dashboard/DashboardOverview";
import { PostcodeManagementOverview } from "../../sites/PostcodeManagement/PostcodeManagementOverview";
import { SystemMailOverview } from "../systemMail/SystemMailOverview";
import CalendarMain from "../calendar/CalendarMain";

const PageRouter: React.FC = () => {
  return (
    <>
      <NavBar>
        <Routes>
          <Route path="/" element={<DashboardOverview />} />

          <Route path="/calendar" element={<CalendarMain />} />
          <Route path="/customers" element={<ProtectedRoute element={<CustomerOverview />} requiredRoles={[1,2,3,4]} />} />
          <Route path="/healthinsurances" element={<ProtectedRoute element={<InsuranceOverview />} requiredRoles={[1,2,3,4]} />} />
          <Route path="/listofservices" element={<ProtectedRoute element={<ServiceOverview />} requiredRoles={[1,2]} />} />
          <Route path="/listofmaterials" element={<ProtectedRoute element={<MaterialOverview />} requiredRoles={[1,2,3,4]} />} />
          <Route path="/partners" element={<ProtectedRoute element={<PartnerOverview />} requiredRoles={[1,2,3,4]} />} />
          <Route path="/property_managment" element={<ProtectedRoute element={<PropertyManagementOverview />} requiredRoles={[1,2,3,4]} />} />
          <Route path="/projects" element={<ProjectOverview />}/>
          <Route path="/templates" element={<ProtectedRoute element={<SystemMailOverview />} requiredRoles={[1,2]} />} />
          <Route path="/postcode" element={<ProtectedRoute element={<PostcodeManagementOverview />} superuserOnly={true} requiredRoles={[0]}/>}/>
          <Route path="/roles" element={<ProtectedRoute element={<RoleManagementOverview />} superuserOnly={true} requiredRoles={[0]}/>}/>
          <Route path="/no-permission" element={<NoPermission />} />
        </Routes>
      </NavBar>
    </>
  );
};

export default PageRouter;
