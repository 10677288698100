import React, { useEffect, useState } from "react";
import { deleteFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import { IService } from "../../interfaces/IService";
import {Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, TextField, Typography, useScrollTrigger} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, deDE } from "@mui/x-data-grid";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IUnit } from "../../interfaces/IUnit";
import { ServiceEdit } from "../../components/service/ServiceEdit";
import { CustomSnackbar } from "../../components/core/Snackbar";
import { IFeedback } from "../../interfaces/IFeedback";
import { Delete } from "@mui/icons-material";
import { usePermissions } from "../../contexts/permissions/PermissionContext";

const getEmptyService = () => {
    return {
        articelNumber: "",
        description: "",
        id: -1,
        isPaidNursingCareFund: false,
        ServiceCategory: 1,
        timeFactor: 0,
        Unit: 1
    } as IService
}


export const ServiceOverview:React.FC = () => {
    const [serviceArray,setServiceArray,statusCode] = useFetch<IService[]>("/service/");
    const [serviceCategoryArray,setServiceCategoryArray,statusCodeServiceCategory] = useFetch<IServiceCategory[]>("/servicecategory/");
    const [unitArray,setUnitArray,statusCodeUnit] = useFetch<IUnit[]>("/unit/");
    //
    const [editService,setEditService] = useState(getEmptyService());
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoadgin,setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    //
    const [feedback,setFeedback] = useState<IFeedback>({ open: false,message: "Gespeichert!", severity: "success" } as IFeedback)

    const [searchTerm, setSearchTerm] = useState("");

    //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [serviceIdToDelete, setServiceIdToDelete] = React.useState<number | null>(null);
    const is_superuser = localStorage.getItem("is_superuser")
    //const { permissions } = usePermissions();

    const openDeleteConfirmationDialog = (serviceId: number) => {
        setServiceIdToDelete(serviceId);
        setOpenDeleteDialog(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setServiceIdToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleRemove = (serviceId: number) => {
        if (is_superuser === "true"){
            deleteFetch("/service/", serviceId,setWasSuccessfully)
            setFeedback({ open: true,message: "Leistung erfolgreich gelöscht!", severity: "success" })
            const newArray = (serviceArray === undefined) ? "" : serviceArray.filter(service => service.id !== serviceId);
            setServiceArray(newArray)
            closeDeleteConfirmationDialog();
        }else{
            console.log("You have no permission.")
        }   
    };
//END OF DELETE PATTERNS

    const columns: GridColDef[] = [
        { 
            field: 'articelNumber', 
            headerName: 'Art.Nr.', 
            width: 90 
        },
        { 
            field: 'ServiceCategory', 
            headerName: 'Kategorie', 
            valueGetter: (params) => (serviceCategoryArray !== undefined) && serviceCategoryArray.find(x => x.id === params.row.ServiceCategory)?.serviceCategory,
            width: 250 
        },
        { 
            field: 'Unit', 
            headerName: 'Einheit', 
            valueGetter: (params) => (unitArray !== undefined) && unitArray.find(x => x.id === params.row.Unit)?.unit,
            width: 90 
        },
        { 
            field: 'isPaidNursingCareFund', 
            headerName: 'PK', 
            valueGetter: (params) => (params.row.isPaidNursingCareFund) ? "Ja" : "Nein",
            width: 90 
        },
        { 
            field: 'title', 
            headerName: 'Bezeichnung', 
            flex: 1
        },
        { 
            field: 'action', 
            headerName: 'Aktionen', 
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return (
                    <> {(is_superuser == "true" || true) && <>
                        <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                            <Delete />
                        </IconButton>
                        <Dialog
                            open={openDeleteDialog && serviceIdToDelete === params.row.id}
                            onClose={closeDeleteConfirmationDialog}
                        >
                            <DialogTitle>Bestätigung Löschung</DialogTitle>
                            <DialogContent>
                                Sind Sie sicher, dass Sie "{params.row.title}" löschen wollen?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                    Abbruch
                                </Button>
                                <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                    Bestätigen
                                </Button>
                            </DialogActions>
                        </Dialog></>}
                    </>
                );
            },
            width: 100
        },

    ]
    

    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = serviceArray?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditService(getEmptyService())
        } else {
            setEditService(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditService(getEmptyService());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject:IService) => {
        if (serviceArray !== undefined) {
            setFeedback({ open: true,message: "Gespeichert!", severity: "success" } )
        
            let testObject = serviceArray.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setServiceArray([
                    ...serviceArray,
                    savedObject
                ])
            }
            else {
                setServiceArray([
                    ...serviceArray.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }

    
    const handleSave = () => {
        setWasSuccessfully(true);
        if (editService.id < 0) {
            uploadFetch("/service/",true,editService,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        } else {
            uploadFetch(`/service/${editService.id}`,false,editService,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({ open: true,message: "Ein fehler ist aufgetretten!", severity: "error" } )
            setIsLoading(false);
        }
    },[wasSuccessfully])

    const handleSearchTerm = () => {
        if (serviceArray === undefined) {
            return [];
        }
        else if (searchTerm === "") {
            return serviceArray
        } else {
            return serviceArray.filter(x =>
                String(x.title).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.description).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.articelNumber).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }


    if (
        (statusCode !== null && statusCode !== 200)
        || (statusCodeServiceCategory !== null && statusCodeServiceCategory !== 200)
        || (statusCodeUnit !== null && statusCodeUnit !== 200)
    ) { return <Alert severity="error">Fehler!</Alert> }

    else if (
        serviceArray === undefined
        || serviceCategoryArray === undefined
        || unitArray === undefined
    ) { return <LinearProgress/> }

    else {
        return(
            <>
                <Typography variant="h5">
                    Leistungsverzeichnis
                   
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={handleSearchTerm()}
                            columns={columns}
                            //getRowId={(row) => row.id}
                            onRowClick={(params, event, details) => {
                                if(true){
                                const target = event.target as HTMLElement;
                                const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                                if (isDeleteIconClicked) {
                                    event.stopPropagation();
                                    openDeleteConfirmationDialog(Number(params.id));
                                } else {
                                    handleChangeId(params)
                                }
                            }}}
                            autoHeight
                        />
                    </Grid>

                    <Dialog open={isOpen} maxWidth="md" onClose={() => setIsOpen(false)}>
                        <DialogContent>
                            <ServiceEdit
                                key={`editService-${editService.id}`}
                                serviceObject={editService}
                                setServiceObject={setEditService}
                                serviceCategoryArray={serviceCategoryArray}
                                unitArray={unitArray}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsOpen(false)}  variant="outlined">Abbruch</Button>
                            <Button  onClick={handleSave}  variant="contained" disabled={isLoadgin || editService.articelNumber === "" || editService.title === ""}>Speichern</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </>
        )
    }


}