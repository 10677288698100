import React, { useEffect, useState } from "react";
import { IOffer } from "../../interfaces/IOffer";
import { IPartner } from "../../interfaces/IPartner";
import { IUnit } from "../../interfaces/IUnit";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IOfferState } from "../../interfaces/IOfferState";
import { IOfferType } from "../../interfaces/IOfferType";
import { IOfferPosition } from "../../interfaces/IOfferPosition";
import { AppBar, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, List, MenuItem, Slide, TableCell, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { GridCloseIcon } from "@mui/x-data-grid";
import { OfferEdit } from "./OfferEdit";
import { Create, Download, NoteAlt } from "@mui/icons-material";
import { OfferSignedPage } from "./OfferSignedPage";
import { IDocument } from "../../interfaces/IDocument";
import { base64Download } from "../core/generic";
import { ICustomer } from "../../interfaces/ICustomer";
import { IProject } from "../../interfaces/IProject";
import { ITemplate } from "../../interfaces/ITemplate";
import { CustomeTextField } from "../core/CustomeTextField";
import { IOfferApplicationDocument } from "../../interfaces/IOfferApplicationDocument";
import { uploadFetch } from "../../hooks/useFetch";
import { CustomCircularProgress } from "../core/CustomCircularProgress";
import { IMaterial } from "../../interfaces/IMaterial";
import { IMaterialCategory } from "../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../interfaces/IMaterialSubcategory";
import { OfferDocumentPublic } from "./OfferDocumentPublic";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { IMaterialDocument } from "../../interfaces/IMaterialDocument";
import { MaterialDocumentRow } from "../material/MaterialDocument/MaterialDocumentRow";
import { IReasonCancellation } from "../../interfaces/IReasonCancellation";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  




interface IProps {
    offerObject: IOffer;
    offerArray: IOffer[];
    setOfferArray: Function;
    projectObject: IProject;
    setProjectObject: Function;

    reasonCancellationArray: IReasonCancellation[];

    templateArray: ITemplate[];
    customerArray: ICustomer[];
    partnerArray: IPartner[];
    unitArray: IUnit[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];

    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];

    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];

    materialDocumentArray: IMaterialDocument[] | undefined;

    systemMailArray: ISystemMail[];
}


export const OfferRow:React.FC<IProps> = ({
    offerObject,
    offerArray,
    setOfferArray,
    projectObject,
    setProjectObject,

    reasonCancellationArray,

    templateArray,
    customerArray,
    partnerArray,
    unitArray,
    serviceArray,
    serviceCategoryArray,
    materialArray,
    materialCategoryArray,
    materialSubcategoryArray,
    offerStateArray,
    offerTypeArray,
    materialDocumentArray,
    systemMailArray
}) => { 
    const is_superuser = localStorage.getItem("is_superuser") === "true";

    const [isOpenQuestion, setIsOpenQuestion] = useState(false);
    const [isEditOpen,setIsEditOpen] = useState(false);
    //
    const [isLoading,setIsLoading] = useState(false);

    //
    const [isOpenResetApplication, setIsOpenResetApplication] = useState(false);
    //
    const [isOpenSignedField,setIsOpenSignedField] = useState(false);
    const [isLoadingReset,setIsLoadingReset] = useState(false);
    //
    const [isOpenSelectCustomer, setIsOpenSelectCustomer] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(projectObject.Participant.find(x => x.is_contact_person)?.Customer);
    //
    const [currentfferApplicationDocument, setCurrentfferApplicationDocument] = useState<null|IOfferApplicationDocument>(null);
    const [notFound, setNotFound] = useState(false);

    const handleClose = () => {
        setIsEditOpen(false);
    }

    const handleSave = () => {
        setIsEditOpen(false);
    }

    const handleDownload = () => {
        if (offerObject.OfferApplicationDocuments !== undefined) {
            offerObject.OfferApplicationDocuments.map(x => {
                if (x.Document_Signed !== null) {
                    base64Download(x.Document_Signed);
                }
                else if (x.Document_Data !== null) {
                    base64Download(x.Document_Data);
                }
            })
        }


        {/*
        if (offerObject.signed_data !== null) {
            currentData.data = offerObject.signed_data
        }
        else if (offerObject.data !== null) {
            currentData.data = offerObject.data
        }
        */}

        
    }

    const handleOpenSigned = () => {
        console.log(offerObject.OfferApplicationDocuments)
        let foundObject = offerObject.OfferApplicationDocuments?.find(x => x.Customer === selectedCustomer);

        if (foundObject == undefined) {
            setNotFound(true);
            setIsOpenSelectCustomer(false);
            console.log("Not found")
        }
        else {
            if (foundObject.Document_Signed === null) {
                setCurrentfferApplicationDocument(foundObject);
            } else {
                base64Download(foundObject.Document_Signed);
            }
            setIsOpenSelectCustomer(false);
            
        }
    }
    

    useEffect(() => {
        console.log("UPDAATE")
        console.log(offerObject.OfferApplicationDocuments)
    },[offerObject.OfferApplicationDocuments])

    const handleAfterReset = (res:IOfferApplicationDocument) => {
        console.log("TEST!")
        console.log(res)
        setOfferArray([
            ...offerArray.map(x => x.id !== offerObject.id ? x : {
                ...offerObject,
                OfferApplicationDocuments: [...offerObject.OfferApplicationDocuments!.map(x => x.id === res.id ? res : x)]
            }
            )
        ])
        setIsOpenResetApplication(false);

    }

    const handleResetApplication = () => {
        let foundObject = offerObject.OfferApplicationDocuments?.find(x => x.Customer === selectedCustomer);

        if (foundObject == undefined) {
            setNotFound(true);
            setIsOpenSelectCustomer(false);
        }
        else {
            let clearDocument = {...foundObject, Document_Signed: null, Document_Signed_Field: null}
            uploadFetch(`/offer/applications/document/${clearDocument.id}`,false,clearDocument,handleAfterReset,() => {},() => {},setIsLoadingReset)
            
        }
    }


    const handleCloseQuestion = () => {
        setIsOpenQuestion(false);
        setIsEditOpen(true);
    }

    const afterSaveProject = (res:IProject) => {
        setIsLoading(false);
        setProjectObject(res);
        handleCloseQuestion()
    }


    const handleSavProject = () => {
        uploadFetch(`/project/${projectObject.id}`,false,projectObject,afterSaveProject,()=>{},()=>{},setIsLoading)
    }




    return(
        <>
            <Dialog open={isOpenQuestion} onClose={handleCloseQuestion}>
                {(isLoading)
                    ? <DialogContent><CustomCircularProgress/></DialogContent>
                    :   <>
                        <DialogContent>
                            Wollen Sie das Projekt (zwischen-)speichern, sodass Ihre Änderungen (Kundenzuordnung, Mitarbeiter etc.) im Angebot verfügbar sind?
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleCloseQuestion}>Nein</Button>
                            <Button variant="contained" onClick={handleSavProject}>Ja</Button>
                        </DialogActions>
                    </>
                }

            </Dialog>


            <Dialog open={isOpenResetApplication} onClose={() => setIsOpenResetApplication(false)} maxWidth="md" fullWidth>
                <DialogTitle>Bestätigung der Zurücksetzung</DialogTitle>
                <DialogContent>
                    <Collapse in={isLoadingReset}>
                        <LinearProgress/>
                    </Collapse>
                    Sind Sie sicher, dass Sie die Dokumente von <i>{customerArray.find(x => x.id === selectedCustomer)?.lastname}</i> zurücksetzen wollen?
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoadingReset} variant="outlined" onClick={handleResetApplication}>Zurücksetzen</Button>
                    <Button disabled={isLoadingReset} variant="contained" onClick={() => setIsOpenResetApplication(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>



            <Dialog open={isOpenSelectCustomer} onClose={() => setIsOpenSelectCustomer(false)} maxWidth="sm" fullWidth>
                <DialogContent>
                    <CustomeTextField
                        label="Beteiligter"
                        attr="selectedCustomer"
                        object={{"selectedCustomer" : selectedCustomer}as any}
                        setObject={(x:{selectedCustomer : number}) => setSelectedCustomer(x.selectedCustomer)}
                        type="select"
                    >
                        {   customerArray
                            .filter(x => projectObject.Participant.map(x => x.Customer).indexOf(x.id) > -1)
                            .map(x => 
                                <MenuItem key={`select-customer-${x.id}`} value={x.id}>
                                    {x.firstname} {x.lastname}
                                    {(offerObject.OfferApplicationDocuments !== undefined) &&
                                        (
                                            offerObject.OfferApplicationDocuments
                                            .filter(y => y.Document_Signed !== null)
                                            .find(y => y.Customer === x.id) !== undefined
                                        )
                                        ? <Typography variant="caption" sx={{ml: 2}}> Nur Download</Typography>
                                        : <></>
                                    }
                                </MenuItem>
                            )

                        }
                        </CustomeTextField>
                </DialogContent>
                <DialogActions>
                    {(is_superuser && offerObject.OfferState < 3) && 
                        <Button variant="outlined" onClick={() => setIsOpenResetApplication(true)}>Zurücksetzen</Button>
                    }
                    <OfferDocumentPublic
                        contactArray={customerArray}
                        offerObject={offerObject}
                        selectedContact={selectedCustomer}
                        systemMailArray={systemMailArray}
                    />
                    <Button variant="outlined" onClick={() => setIsOpenSelectCustomer(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleOpenSigned}>Öffnen</Button>
                </DialogActions>
            </Dialog>
        


            <Dialog
                fullScreen
                open={currentfferApplicationDocument !== null}
                onClose={() => setCurrentfferApplicationDocument(null)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setCurrentfferApplicationDocument(null)}
                        aria-label="close"
                    >
                    <GridCloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Kundenansicht
                    </Typography>
                </Toolbar>
                </AppBar>
                <Box sx={{m: 5}}>
                    {(currentfferApplicationDocument !== null) &&
                        <OfferSignedPage
                            editOfferDocument={currentfferApplicationDocument}
                            setCurrentOfferApplicationDocument={setCurrentfferApplicationDocument}
                            offerArray={offerArray}
                            setOfferArray={setOfferArray}
                            offerObject={offerObject}
                        />
                    }

                </Box>
            </Dialog>

            <Dialog
                fullScreen
                open={isEditOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                    <GridCloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Angebotseditor
                    </Typography>
                </Toolbar>
                </AppBar>
                <Box style={{marginBottom: 0}}  sx={{m: 5}}>
                    <OfferEdit
                        key={`offer-${offerObject.id}`}
                        customerArray={customerArray}
                        projectObject={projectObject}
                        offerObject={offerObject}
                        offerArray={offerArray}
                        reasonCancellationArray={reasonCancellationArray}
                        setOfferArray={setOfferArray}
                        offerStateArray={offerStateArray}
                        offerTypeArray={offerTypeArray}
                        partnerArray={partnerArray}
                        templateArray={templateArray}
                        serviceArray={serviceArray}
                        serviceCategoryArray={serviceCategoryArray}
                        materialArray={materialArray}
                        materialCategoryArray={materialCategoryArray}
                        materialSubcategoryArray={materialSubcategoryArray}
                        unitArray={unitArray}
                        materialDocumentArray={materialDocumentArray}
                        handleSave={handleSave}
                        handleDownload={handleDownload}
                    />
                </Box>
            </Dialog>

            <TableRow >
                <TableCell sx={{cursor: "pointer"}} onClick={() => setIsOpenQuestion(true)}>{offerObject.id}</TableCell>
                <TableCell sx={{cursor: "pointer"}} onClick={() => setIsOpenQuestion(true)}>{offerTypeArray.find(y => y.id === offerObject.OfferType)?.offerType}</TableCell>
                <TableCell sx={{cursor: "pointer"}} onClick={() => setIsOpenQuestion(true)}>{offerStateArray.find(y => y.id === offerObject.OfferState)?.offerState}</TableCell>
                <TableCell sx={{cursor: "pointer"}} onClick={() => setIsOpenQuestion(true)}>{offerObject.title}</TableCell>
                <TableCell>
                    <IconButton onClick={() => setIsOpenQuestion(true)}><Create/></IconButton>

                    <IconButton disabled={offerObject.OfferApplicationDocuments === null} onClick={() => setIsOpenSelectCustomer(true)}><NoteAlt/></IconButton>
                    <IconButton disabled={offerObject.OfferApplicationDocuments === null} onClick={handleDownload}><Download/></IconButton>


                </TableCell>
            </TableRow>
        </>
    )
    // <IconButton disabled={offerObject.data === null || offerObject.signed_field !== null} onClick={() => setIsOpenSignedField(true)}><Create/></IconButton>
}