import { useMutation, useQuery } from "@tanstack/react-query";
import { customFetcher } from "../useFetch";
import { IProjectState } from "../../interfaces/IProjectState";
import { IProject } from "../../interfaces/IProject";
import { IProjectLog } from "../../interfaces/IProjectLog";


export const useGetProjectState = () => {
    const {
      data: projectStateArray,
      isLoading: isLoadingProjectStateArray,
      refetch: refetchProjectState,
    } = useQuery<IProjectState[]>({
      queryKey: ['project/state/'],
      queryFn: () => customFetcher({ endpoint: '/project/state/' }),
    });
    return { projectStateArray, isLoadingProjectStateArray, refetchProjectState};
  };

export const useGetProjectId = () => {
  const {
    mutateAsync: getProjectById,
    isPending: isPendingProjectById,
  } = useMutation<IProject, unknown, number>({
    mutationFn: async (idProject: number) => {
      const response = await customFetcher({
        endpoint: `/project/${idProject}`,
      });
      return response as IProject;
    },
  });

  return {
    getProjectById,
    isPendingProjectById,
  };
};
  


export const useGetLogByProjecId = (idProject:number) => {
  const {
    data: logArray,
    isLoading: isPendingLogArray,
    refetch: refetechLogArrayByProjectId,
  } = useQuery<IProjectLog[]>({
    queryKey: ['project/log/byProject/',idProject],
    queryFn: () => customFetcher({ endpoint: `/project/log/byProject/${idProject}` }),
  });
  return { logArray, isPendingLogArray, refetechLogArrayByProjectId};
};


export const useGetLogStatistic = () => {
  const {
    data: logArray,
    isLoading: isPendingLogArray,
    refetch: refetechLogArrayByProjectId,
  } = useQuery<IProjectLog[]>({
    queryKey: ['project/log/statistic/'],
    queryFn: () => customFetcher({ endpoint: `/project/log/statistic/` }),
  });
  return { logArray, isPendingLogArray, refetechLogArrayByProjectId};
};