import React, { useState } from "react";
import { Alert, Box, Button, CircularProgress, Collapse, Grid, TextField, Typography } from "@mui/material";
import { CustomSnackbar } from "../../components/core/Snackbar";
import { IFeedback } from "../../interfaces/IFeedback";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { PostcodeEdit } from "../../components/postcode/PostcodeEdit";
import { IPostcode } from "../../interfaces/IPostcode";
import { useGetCountry, useGetPostcode, useGetState, useMutatePostcode } from "../../hooks/data/data_postcode";

export const getEmptyPostcode = () => {
  return {
    city: "",
    id: -1,
    postcode: "",
    state: 3,
    country: 1
  } as IPostcode
}


export const PostcodeManagementOverview:React.FC = () => {
    const [inputSearchTerm,setInputSearchTerm] = useState("Kremmen");
    const [searchTerm,setSearchTerm] = useState("Kremmen");
    //
    const { postcodeArray, isLoadingPostcode, refetchPostcodeArray  } = useGetPostcode(searchTerm);
    const { countryArray, isLoadingCountry, refetchCountryArray  } = useGetCountry();
    const { stateArray, isLoadingState, refetchStateArray } = useGetState();
    const { mutatePostcode, isPendingMutatePostcode } = useMutatePostcode();
    //
    const [editPostcode,setEditPostcode] = useState(getEmptyPostcode());
    //
    const [isOpen, setIsOpen] = useState(false);
    //
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)

    const isPending = isPendingMutatePostcode;

    const refetchCountryState = () => {
      refetchStateArray();
      refetchCountryArray();
    }
    const refetchAll = () => {
      refetchCountryState();
      refetchPostcodeArray();
    }

    const columns: GridColDef[] = [
      {
          field: 'id',
          headerName: 'Nr.',
          valueGetter: (params) => params.row.id,
          width: 70
      },

      {
          field: 'country',
          headerName: 'Land',
          valueGetter: (params) => countryArray?.find(country => country.id === Number(params.value))?.country,
          width: 150
      },

      {
          field: 'state',
          headerName: 'Bundesland',
          valueGetter: (params) => stateArray?.find(state => state.id === Number(params.value))?.state,
          width: 200
      },

      {
          field: 'postcode',
          headerName: 'PLZ',
          width: 100
      },
      {
          field: 'city',
          headerName: 'Stadt',
          flex: 1
      },
    ]


    const handleSearch = () => {
      setSearchTerm(inputSearchTerm);
    }

    const handleNew = () => {
      setEditPostcode(getEmptyPostcode());
      setIsOpen(true);
    }

    const handleSave = async () => {
      try {
        let updatedObject = await mutatePostcode(editPostcode);
        setEditPostcode(updatedObject);

        if (editPostcode.id > 0) {
          setFeedback({ open: true,message: "PLZ-Objekt erfolgreich aktualisiert!", severity: "success" })
        }
        else {
          setFeedback({ open: true,message: "PLZ-Objekt erfolgreich erstellt!", severity: "success" })
        }
        
        refetchPostcodeArray();
      } catch (error) {
        console.error(error);
        setFeedback({ open: true,message: "Es ist ein Fehler aufgeretten!", severity: "error" })
      }

    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    if (isLoadingCountry || isLoadingState) {
      return <CircularProgress/>
    }

    if (!countryArray || !stateArray) {
      return(
        <Box
          sx={{
            minHeight: '50vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Typography variant='h6'>
            Fehler beim Laden der Daten. Bitte versuchen Sie es erneut.
          </Typography>
          <Button
            variant='contained'
            onClick={() => refetchAll()}
          >
            Erneut versuchen
          </Button>
        </Box>
      )
    }

    return(
            <>
                <Box>
                  <Typography variant="h5">
                      Postleitzahlen
                      <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Postleitzahl hinzufügen</Button> 
                  </Typography>
                </Box>

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>
                <Box sx={{mt: 2}}/>

                <Collapse in={inputSearchTerm.length < 3}>
                  <Alert severity="warning">
                    Bitte geben Sie mindestens drei Zeichen für die Suche ein.
                  </Alert>
                </Collapse>

                <Box sx={{display: "flex", mt: 2}}>
                  <TextField
                      label="Suche"
                      fullWidth
                      value={inputSearchTerm}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputSearchTerm(event.target.value)}
                      onKeyDown={(ev) => (inputSearchTerm.length >= 3 && ev.key === 'Enter') && handleSearch()}
                      onFocus={()=> setInputSearchTerm("")}
                  />
                  <Button 
                    sx={{ml: 2}} 
                    variant="outlined"
                    disabled={inputSearchTerm.length < 3}
                    onClick={handleSearch}
                  >Suche</Button>
                </Box>

                {(isLoadingPostcode)
                  ? <Box sx={{m: 5}}>
                      <CircularProgress/>
                      Je nach Suchanfrage kann das Laden der Ergebnisse mehrere Minuten dauern.
                    </Box>
                  : (!postcodeArray) ?
                    <Box
                      sx={{
                        minHeight: '50vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography variant='h6'>
                        Fehler beim Laden der Daten. Bitte versuchen Sie es erneut.
                      </Typography>
                      <Button
                        variant='contained'
                        onClick={() => refetchPostcodeArray()}
                      >
                        Erneut versuchen
                      </Button>
                    </Box>
                  :
                    <Grid container spacing={2} sx={{mt: 2}}>
                        <Grid item sm={(isOpen) ? 6 : 12}>
                            <Box sx={{ height: '80vh', overflow: 'auto' }}>
                              <DataGrid
                                  rows={postcodeArray}
                                  columns={columns}
                                  //getRowId={(row) => row.id}
                                  onRowClick={(params, event, details) => {
                                    let testObject = postcodeArray.find(x => x.id === params.id)
                                    if (testObject !== undefined) {
                                      setEditPostcode(testObject);
                                      setIsOpen(true);
                                    }
                                  }}
                                  autoHeight
                              />
                            </Box>
                        </Grid>
                        <Grid item sm={(isOpen) ? 6 : 0}>
                            <Collapse in={isOpen}>
                                <form onSubmit={handleSubmit}>
                                    <Typography variant="h6">
                                        PLZ
                                        <Button
                                          sx={{float: "right", ml: 2}}
                                          variant='contained'
                                          disabled={isPending}
                                          type="submit"
                                        >
                                          {isPending ? (
                                            <Box display='flex' alignItems='center' gap={1}>
                                              <span>Speichern</span>
                                              <CircularProgress size={20} />
                                            </Box>
                                          ) : (
                                            'Speichern'
                                          )}
                                        </Button>
                                        <Button sx={{float: "right"}} onClick={() => setIsOpen(false)}
                                                variant="outlined">Abbruch</Button>
                                    </Typography>
                                    <PostcodeEdit
                                      postcodeObject={editPostcode}
                                      setPostcodeObject={setEditPostcode}
                                      countryArray={countryArray}
                                      stateArray={stateArray}
                                      refetchCountryState={refetchCountryState}
                                    />
                                </form>
                            </Collapse>
                        </Grid>
                    </Grid>
                }
            </>
    )
}