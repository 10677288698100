import React, {useEffect, useState} from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {deleteFetch, uploadFetch, useFetch, useSessionFetch} from "../../hooks/useFetch";
import { ICalendarEvent } from "../../interfaces/ICalendarEvent";
import { ICalendarAPIEvent } from "../../interfaces/ICalendarAPIEvent";
import CalendarEventEdit from './CalendarEventEdit';
import FullCalendar from "@fullcalendar/react";
import { EventClickArg } from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import {Button, CircularProgress} from "@mui/material";
import {IProfile} from "../../interfaces/IUser";


const CalendarMain: React.FC = () => {
    const [refresh, setRefresh] = useState(0);
    const [events, setEvents, statusCode, loading] = useSessionFetch<ICalendarAPIEvent[]>(`/calendar/events/?refresh=${refresh}`);

    const [selectedEvent, setSelectedEvent] = useState<ICalendarEvent | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userArray, setUserArray, statusCodeUser] = useFetch<IProfile[]>("/user/");

    useEffect(() => {
        console.log(userArray)
    });

    // Transformiere die API-Events in das Format, das FullCalendar benötigt.
    const calendarEvents: ICalendarEvent[] = (events ?? []).map(apiEvent => {
        const startDateObj = new Date(apiEvent.startDate);
        const formattedStart = !isNaN(startDateObj.getTime())
            ? startDateObj.toISOString()
            : apiEvent.startDate;

        // Konvertiere endDate in ISO-String, falls vorhanden.
        let formattedEnd: string | undefined;
        if (apiEvent.endDate) {
            const endDateObj = new Date(apiEvent.endDate);
            formattedEnd = !isNaN(endDateObj.getTime())
                ? endDateObj.toISOString()
                : apiEvent.endDate;
        }

        return {
            id: apiEvent.id,
            title: apiEvent.title,
            start: formattedStart,
            end: formattedEnd,
            allDay: apiEvent.wholeDay,
            extendedProps: {
                description: apiEvent.description,
                who: apiEvent.who,
                where: apiEvent.where,
                subCalendars: apiEvent.subCalendars,
                createdTime: apiEvent.createdTime,
                editedTime: apiEvent.editedTime,
                createdName: apiEvent.createdName,
                editedName: apiEvent.editedName,
                wholeDay: apiEvent.wholeDay,
                links: apiEvent.links,
            }
        };
    });


    // Handler, wenn ein Termin im Kalender angeklickt wird.
    const handleEventClick = (clickInfo: EventClickArg) => {
        const eventApi = clickInfo.event;
        const extendedProps = {
            description: eventApi.extendedProps.description ?? '',
            who: eventApi.extendedProps.who ?? '',
            where: eventApi.extendedProps.where ?? '',
            subCalendars: eventApi.extendedProps.subCalendars ?? [],
            createdTime: eventApi.extendedProps.createdTime ?? '',
            editedTime: eventApi.extendedProps.editedTime ?? null,
            createdName: eventApi.extendedProps.createdName ?? '',
            editedName: eventApi.extendedProps.editedName ?? null,
            wholeDay: eventApi.extendedProps.wholeDay ?? false,
            links: eventApi.extendedProps.links ?? [],
        };
        const clickedEvent: ICalendarEvent = {
            id: eventApi.id,
            title: eventApi.title,
            start: eventApi.start ? eventApi.start.toISOString() : "",
            end: eventApi.end ? eventApi.end.toISOString() : undefined,
            extendedProps,
        };
        setSelectedEvent(clickedEvent);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedEvent(null);
    };

    const convertDatetimeLocalToBackendFormat = (datetimeLocal: string): string => {
        if (!datetimeLocal) return "";
        const [date, time] = datetimeLocal.split("T");
        return `${date} ${time}:00`;
    };

    const handleSaveEvent = (updatedEvent: ICalendarEvent) => {
        const payload = {
            startDate: convertDatetimeLocalToBackendFormat(updatedEvent.start),
            endDate: updatedEvent.end
                ? convertDatetimeLocalToBackendFormat(updatedEvent.end)
                : "",
            timeZone: "Europe/Berlin", // Hier kannst du ggf. dynamisch arbeiten
            title: updatedEvent.title,
            subCalendars: updatedEvent.extendedProps.subCalendars,
            description: updatedEvent.extendedProps.description,
            who: updatedEvent.extendedProps.who,
            where: updatedEvent.extendedProps.where,
            wholeDay: updatedEvent.extendedProps.wholeDay,
            links: updatedEvent.extendedProps.links,
        };
        if ((parseInt(updatedEvent.id) < 0) || (updatedEvent.id == "")) {
            uploadFetch(
                `/calendar/events/create/`,
                true,
                payload,
                (res: any) => {
                    console.log("Erstellen erfolgreich:", res);
                    setRefresh(prev => prev + 1)
                    setDialogOpen(false);
                    setSelectedEvent(null);
                }
            );
        }
        else {
            if (parseInt(updatedEvent.id) > 0) {
                uploadFetch(
                    `/calendar/events/${updatedEvent.id}/`,
                    false,
                    payload,
                    (res: any) => {
                        console.log("Update erfolgreich:", res);
                        setRefresh(prev => prev + 1)
                        setDialogOpen(false);
                        setSelectedEvent(null);
                    }
                );
            }
        }
    };

    const handleDelete = () => {
        if (selectedEvent && parseInt(selectedEvent.id) > 0) {
            deleteFetch(
                `/calendar/events/`,
                parseInt(selectedEvent.id),
                (wasSuccessfullyDeleted: boolean) => {
                    if (wasSuccessfullyDeleted) {
                        console.log("Delete successful");
                        setRefresh(prev => prev + 1)
                        setDialogOpen(false);
                        setSelectedEvent(null);
                    } else {
                        console.error("Delete failed");
                    }
                }
            );
        }
    };

    const handleDateClick = (arg: { date: Date }) => {
        const clickedDate = arg.date;
        const newEvent: ICalendarEvent = {
            id: "", // leer lassen für neues Event
            title: "",
            start: clickedDate.toISOString(),
            end: new Date(clickedDate.getTime() + 60 * 60 * 1000).toISOString(), // default: 1h später
            extendedProps: {
                description: "",
                who: "",
                where: "",
                subCalendars: [],
                createdTime: "",
                editedTime: null,
                createdName: "",
                editedName: null,
                wholeDay: false,
                links: [],
            }
        };

        setSelectedEvent(newEvent);
        setDialogOpen(true);
    };

    if (!statusCode || !statusCodeUser || userArray == undefined) {
        return <CircularProgress/>;
    }
    if (statusCode !== 200 || statusCodeUser !== 200) {
        return <div>Fehler beim Laden der Termine: {statusCode}</div>;
    }
    return (
        <div style={{ margin: '20px' }}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setRefresh(prev => prev + 1)}
                style={{ marginBottom: '16px' }}
            >
                Aktualisieren
            </Button>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                locale={deLocale}
                initialView="timeGridWeek"
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }}
                events={calendarEvents}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
                eventDidMount={(info) => {
                    const subCalendars: string[] = info.event.extendedProps.subCalendars || [];
                    let bgColor = "#6fa2fc"; // Standardfarbe, wenn kein subCalendar angegeben ist.

                    if (subCalendars.length === 1) {
                        const user = userArray.find(u => u.sub_Calendar === subCalendars[0]);
                        if (user && user.calendar_color) {
                            bgColor = user.calendar_color;
                        }
                    } else if (subCalendars.length > 1) {
                        bgColor = "#0059ff";
                    }
                    info.el.style.backgroundColor = bgColor;
                }}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                firstDay={1}
                height='auto'
                dayMaxEvents={10}
            />
            <CalendarEventEdit
                open={dialogOpen}
                event={selectedEvent}
                onClose={handleCloseDialog}
                onSave={handleSaveEvent}
                onDelete={handleDelete}
                userArray={userArray}
            />
        </div>
    );
};

export default CalendarMain;
