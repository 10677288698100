import React from "react";
import { IChartData } from "./SalesDasboard";
import { Box, Typography } from "@mui/material";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";


interface IProps {
    chartData: IChartData[]
}


export const ProjectDistributionChart:React.FC<IProps> = ({chartData}) => {
    const COLORS = [
        'rgb(189, 189, 189)', // Anfrage
        'rgb(237, 108, 2)',  // Vorabsprache
        'rgb(177, 199, 178)',  // B-Termin
        'rgb(131, 216, 135)', // Angebot
        'rgb(73, 201, 79)', // Antragstellung
        'rgb(72, 170, 77)', // Beauftragt
        'rgb(46, 125, 50)', // Laufend
        'rgb(2, 136, 209)', // Abbrechnung
        'rgb(241, 151, 151)', //Nacharbeit
        'rgb(110, 190, 233)', // Abschluss
        'rgb(18, 51, 20)', // Laufend
        'rgb(211, 47, 47)', // Storniert
        'rgb(211, 47, 47)', // Eskaliert
    ];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = (props:any) => {
        const radius = Number(props["innerRadius"]) + (Number(props["outerRadius"]) - Number(props["innerRadius"])) * 0.5;
        const x = Number(props["cx"]) + radius * Math.cos(-Number(props["midAngle"]) * RADIAN);
        const y = Number(props["cy"]) + radius * Math.sin(-Number(props["midAngle"]) * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > Number(props["cx"]) ? 'start' : 'end'} dominantBaseline="central">
            
            {`${(Number(props["percent"]) * 100).toFixed(0)}%`}
          </text>
        );
    };

    return(
        <>
                        
        <Typography variant="h6" sx={{mt: 3}}>Projektverteilung</Typography>
        <Box sx={{ height: 500, mt: 1 }}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Pie
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        //label={(a: any) => { console.log(a); return `${a["name"]} (${a["value"]})` }}
                        outerRadius={150}
                        fill="#4cb1ad"
                        dataKey="value"
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Legend/>
                </PieChart>
            </ResponsiveContainer>
        </Box>
        </>
    )
}
