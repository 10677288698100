import React, { useEffect, useState } from "react";
import { IProject } from "../../interfaces/IProject";
import { IContractState } from "../../interfaces/IContractState";
import { IContractType } from "../../interfaces/IContractType";
import { useGenerateContractDocument, useGetContract } from "../../hooks/data/data_contract";
import { CustomCircularProgress } from "../core/CustomCircularProgress";
import { ErrorTryRefetch } from "../core/ErrorTryRefetch";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IContract } from "../../interfaces/IContract";
import { ContractRow } from "./Childs/ContractRow";
import { AddContracts } from "./Childs/AddContracts";
import { ICustomer } from "../../interfaces/ICustomer";
import { IOffer } from "../../interfaces/IOffer";
import { IPartner } from "../../interfaces/IPartner";
import { IFeedback } from "../../interfaces/IFeedback";
import { CustomSnackbar } from "../core/Snackbar";
import { ITemplate } from "../../interfaces/ITemplate";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { IService } from "../../interfaces/IService";
import { IUnit } from "../../interfaces/IUnit";
import { IServiceCategory } from "../../interfaces/IServiceCategory";


interface IProps {
    project: IProject;
    contractStateArray: IContractState[];
    contractTypeArray: IContractType[];
    //
    offerArray: IOffer[] | undefined;
    customerArray: ICustomer[];
    partnerArray: IPartner[];
    //
    templateArray: ITemplate[];
    serviceCategoryArray: IServiceCategory[];
    serviceArray: IService[];
    unitArray: IUnit[];
} 


export const ContractTab:React.FC<IProps> = ({
    project,
    contractStateArray,
    contractTypeArray,
    offerArray,
    customerArray,
    partnerArray,
    templateArray,
    serviceCategoryArray,
    serviceArray,
    unitArray
}) => {
    const { contractArray, isLoadingContractArray, refetchContractArray } = useGetContract(project.id);
    //
    const [contracts,setContracts] = useState<IContract[]>(contractArray || []);
    //
    const [isOpenNew,setIsOpenNew] = useState(false);
    //
    const [feedbackNew, setFeedbackNew] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)


    const handleNewContract = () => {
        setIsOpenNew(true);
    }

    useEffect(() => {
        if (contractArray !== undefined) {
            setContracts(contractArray)
        }
    },[contractArray])


    if (isLoadingContractArray || offerArray === undefined) {
        return <CustomCircularProgress/>
    }

    if (!contractArray) {
        return <ErrorTryRefetch refetchFunction={refetchContractArray} />
    }

    return(
        <>
            <CustomSnackbar feedback={feedbackNew} setFeedback={setFeedbackNew}/>
            
            {isOpenNew &&
                <AddContracts
                    project={project}
                    contractStateArray={contractStateArray}
                    contractTypeArray={contractTypeArray}
                    //
                    offerArray={offerArray || []}
                    customerArray={customerArray}
                    partnerArray={partnerArray}
                    feetback={feedbackNew}
                    setFeedback={setFeedbackNew}
                    handleClose={() => setIsOpenNew(false)}
                    handleSave={() => {refetchContractArray(); setIsOpenNew(false) }}
                />
            }

            <Typography variant="h5">
                Verträge
            </Typography>
            <Button onClick={handleNewContract} sx={{float: "right"}} variant="outlined">Neuer Vertrag</Button>


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 100}}>Nr.</TableCell>
                        <TableCell sx={{width: 200}}>Art</TableCell>
                        <TableCell sx={{width: 200}}>Status</TableCell>
                        <TableCell>Titel</TableCell>
                        <TableCell sx={{width: 160}}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contracts.map(contract => 
                        <ContractRow
                            key={`edit-contract-${contract.id}`}
                            project={project}
                            contractObject={contract}
                            setContractArray={setContracts}
                            contractStateArray={contractStateArray}
                            contractTypeArray={contractTypeArray}
                            templateArray={templateArray}
                            refetchContractArray={refetchContractArray}
                            serviceCategoryArray={serviceCategoryArray}
                            serviceArray={serviceArray}
                            unitArray={unitArray}
                        />
                    ) }
                </TableBody>
            </Table>
        </>
    )
}