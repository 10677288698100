import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from '../../contexts/permissions/PermissionContext';
import { CircularProgress } from '@mui/material';
import { useAuthContext } from '../../contexts/auth/AuthContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
  requiredRoles: number[] | undefined;
  superuserOnly?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, requiredRoles, superuserOnly }) => {
  const { isAuthenticated, loading } = useAuthContext();
  //const { permissions } = usePermissions();
  const isSuperuser = localStorage.getItem("is_superuser") === "true";
  const idRole = Number(localStorage.getItem("idRole"));

  if (loading) {
    return <CircularProgress />;
  }

  const hasPermission = requiredRoles === undefined || isSuperuser || requiredRoles?.indexOf(idRole) !== -1;

  // Check is_superuser
  if (superuserOnly && !isSuperuser) {
    return <Navigate to="/no-permission" />;
  }

  return isAuthenticated && (hasPermission || superuserOnly) ? element : <Navigate to="/no-permission" />;
};

export default ProtectedRoute;
