import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";

/*
 * Version 1.0
 */

interface IProps {
    label: string;
    type:
      | 'password'
      | 'string'
      | 'email'
      | 'number'
      | 'boolean'
      | 'select'
      | 'date'
      | 'euro';
    attr: string;
    object: any;
    setObject: Function;
    required?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    rows?: number;
    children?: React.ReactElement[];
    size?: 'small' | 'medium';
    null?: boolean;
    disabled?: boolean;
    onChangeHandler?: Function;
    onFocus?: Function;
    disableNoValueOption?: boolean;
    error?: boolean;
  }
  
  export const CustomeTextField: React.FC<IProps> = props => {
    const getValue = () => {
      if (
        props.null !== undefined &&
        props.null === true &&
        props.object[props.attr] === null
      ) {
        return '';
      } else {
        return props.object[props.attr];
      }
    };
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChangeHandler !== undefined) {
        props.onChangeHandler(event);
      } else {
        const changeObject = { ...props.object };
  
        if (props.type === 'boolean') {
          changeObject[props.attr] = event.target.value === 'true';
        } else if (
          props.type === 'password' ||
          props.type === 'string' ||
          props.type === 'email' ||
          props.type === 'date'
        ) {
          if (
            props.null !== undefined &&
            props.null === true &&
            event.target.value === ''
          ) {
            changeObject[props.attr] = null;
          } else {
            changeObject[props.attr] = event.target.value;
          }
        } else if (props.type === 'number' || props.type === 'select') {
          if (
            props.null !== undefined &&
            props.null === true &&
            (event.target.value === '' || Number(event.target.value) === -255)
          ) {
            changeObject[props.attr] = null;
          } else {
            changeObject[props.attr] = Number(event.target.value);
          }
        }
  
        props.setObject(changeObject);
      }
    };
  
    const handleChangeNumber = (values: any, sourceInfo: any) => {
      const changeObject = { ...props.object };
      changeObject[props.attr] =
        values.floatValue === undefined ? 0 : values.floatValue;
      props.setObject(changeObject);
    };
  
    if (props.type === 'select') {
      return (
        <>
          <TextField
            //type="email"
            variant={props.variant === undefined ? 'outlined' : props.variant}
            label={props.label}
            fullWidth
            name={`${props.type}-${props.attr}`}
            value={
              props.null && props.object[props.attr] === null
                ? ''
                : Number(props.object[props.attr])
            }
            onChange={handleChange}
            required={props.required == undefined ? false : props.required}
            select
            size={props.size !== undefined ? props.size : 'small'}
            disabled={props.disabled === undefined ? false : props.disabled}
            error={props.error === undefined ? false : props.error}
          >
            {props.null &&
              (props.disableNoValueOption === undefined ||
                props.disableNoValueOption === false) && (
                <MenuItem key='empty-null' value={-255}>
                  <i>Kein Wert</i>
                </MenuItem>
              )}
            {props.children}
          </TextField>
        </>
      );
    } else if (props.type === 'boolean') {
      return (
        <>
          <TextField
            //type="email"
            variant={props.variant === undefined ? 'outlined' : props.variant}
            label={props.label}
            fullWidth
            name={`${props.type}-${props.attr}`}
            value={Boolean(props.object[props.attr]) ? 'true' : 'false'}
            onChange={handleChange}
            required={props.required == undefined ? false : props.required}
            select
            size={props.size !== undefined ? props.size : 'small'}
            disabled={props.disabled === undefined ? false : props.disabled}
            error={props.error === undefined ? false : props.error}
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </>
      );
    } else if (props.type === 'number' || props.type === 'euro') {
      return (
        <>
          <NumericFormat
            name={`${props.attr}`}
            suffix={props.type === 'euro' ? ' €' : undefined}
            value={getValue()}
            fixedDecimalScale
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            onValueChange={handleChangeNumber}
            allowNegative={false}
            label={props.label}
            size={props.size !== undefined ? props.size : 'small'}
            error={props.error === undefined ? false : props.error}
            disabled={props.disabled === undefined ? false : props.disabled}
          />
        </>
      );
    } else {
      return (
        <>
          <TextField
            type={props.type === 'string' ? 'text' : props.type}
            //type="email"
            variant={props.variant === undefined ? 'outlined' : props.variant}
            label={props.label}
            fullWidth
            name={`${props.type}-${props.attr}`}
            value={getValue()}
            onChange={handleChange}
            required={props.required == undefined ? false : props.required}
            multiline={props.rows === undefined ? false : true}
            rows={props.rows}
            size={props.size !== undefined ? props.size : 'small'}
            InputLabelProps={props.type === 'date' ? { shrink: true } : undefined}
            disabled={props.disabled === undefined ? false : props.disabled}
            error={props.error === undefined ? false : props.error}
          />
        </>
      );
    }
  };
  