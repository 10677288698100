import React, { Dispatch, SetStateAction, useState } from "react";
import { IProject } from "../../../interfaces/IProject";
import { IContractState } from "../../../interfaces/IContractState";
import { IContractType } from "../../../interfaces/IContractType";
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Collapse, Dialog, DialogContent, FormControlLabel, FormGroup, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useCreateContractBulk } from "../../../hooks/data/data_contract";
import { IOffer } from "../../../interfaces/IOffer";
import { ICustomer } from "../../../interfaces/ICustomer";
import { IPartner } from "../../../interfaces/IPartner";
import { CustomeTextField } from "../../core/CustomeTextField";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";

interface ICreateContract {
    ContractType: number;
    payload_id: number | null;
    Customer: number | null;
    Partner: number | null;
}

export interface ICreateContractBulk {
    Project: number;
    CreateContractArray: ICreateContract[];
}


interface IProps {
    project: IProject;
    contractStateArray: IContractState[];
    contractTypeArray: IContractType[];
    //
    offerArray: IOffer[];
    customerArray: ICustomer[];
    partnerArray: IPartner[];
    //
    feetback: IFeedback;
    setFeedback: Dispatch<SetStateAction<IFeedback>>;
    //
    handleSave: () => void;
    handleClose: () => void;
} 


export const AddContracts:React.FC<IProps> = ({
    project,
    contractStateArray,
    contractTypeArray,
    offerArray,
    customerArray,
    partnerArray,
    feetback,
    setFeedback,
    handleSave,
    handleClose
}) => {
    const { createContractBulk, isPendingCreateContractBulk } = useCreateContractBulk();
    //
    const [createContractArray,setCreateContractArray] = useState<ICreateContract[]>([]);


    const setCreateContract = (updatedCurrentObject:ICreateContract) => {
        setCreateContractArray(prev =>
            prev.map(x => x.ContractType === updatedCurrentObject.ContractType ? updatedCurrentObject : x)
        )
    }

    const handleUplod = async () => {
        const uploadObject:ICreateContractBulk = {
            Project: project.id,
            CreateContractArray: createContractArray
        }
        try {
            await createContractBulk(uploadObject);
            setFeedback({ open: true,message: "Die Verträge wurden erstellt!", severity: "success" })
            handleSave();
        } catch (error) {
            console.error(error);
            setFeedback({ open: true,message: "Es ist ein Fehler aufgeretten!", severity: "error" })
        }
    }
    
    const onSave = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation(); // Verhindert, dass das Event nach oben propagiert
        handleUplod();
    };
    

    return(
        <>
            <CustomSnackbar feedback={feetback} setFeedback={setFeedback}/>

            <Dialog open maxWidth="md" fullWidth onClose={handleClose}>
                <DialogContent>
                    <form onSubmit={onSave}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Neue Verträge</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Welche Vertragsarten möchten Sie anlegen?</Typography>
                                <FormGroup>
                                    {contractTypeArray.map(contractType =>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        createContractArray.find(x => x.ContractType === contractType.id) !== undefined
                                                    }
                                                    onChange={ event => {
                                                        if (event.target.checked) {
                                                            setCreateContractArray(prev =>
                                                            [
                                                                ...prev,
                                                                {
                                                                    ContractType: contractType.id,
                                                                    Customer: null,
                                                                    Partner: null,
                                                                    payload_id: null
                                                                } as ICreateContract
                                                            ]
                                                            )
                                                        }
                                                        else {
                                                            setCreateContractArray(prev =>
                                                                prev.filter(x => x.ContractType !== contractType.id)
                                                            )
                                                        }
                                                    }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } 
                                            label={contractType.contractType} 
                                        />
                                    )}
                                </FormGroup>
                            </Grid>
                            {createContractArray.map(createContract => {
                                const currentType = contractTypeArray.find(x => x.id === createContract.ContractType);
                                if (!currentType){
                                    return(<Grid item xs={12}>Fehler!</Grid>)
                                }
                                return(
                                    <Grid item xs={12}>
                                        <Card variant="outlined">
                                            <Typography sx={{m: 1}} variant="caption">{currentType.contractType}</Typography>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <CustomeTextField
                                                            attr="payload_id"
                                                            label="Vertrag zum folgenden Angebot"
                                                            object={createContract}
                                                            setObject={setCreateContract}
                                                            type="select"
                                                            required
                                                            null
                                                            disableNoValueOption
                                                        >
                                                            {offerArray.map(offer =>
                                                                <MenuItem key={`offfer-${offer.id}`} value={offer.id}>
                                                                    {offer.title} {(offer.OfferType !== 3 && " (nicht angenommen!)")}
                                                                </MenuItem>
                                                            )}
                                                        </CustomeTextField>
                                                    </Grid>
                                                        <Grid item xs={6}>
                                                            <Collapse in={createContract.payload_id !== null}>
                                                                {currentType.isContractWithCustomer ?
                                                                <CustomeTextField
                                                                    attr="Customer"
                                                                    label="Kunde"
                                                                    object={createContract}
                                                                    setObject={setCreateContract}
                                                                    type="select"
                                                                    required
                                                                    null
                                                                    disableNoValueOption
                                                                >
                                                                    {project.Participant.filter(x => x.is_contact_person).map(part => {
                                                                        let customer = customerArray.find(x => x.id === part.Customer);
                                                                        if (!customer) {
                                                                            <MenuItem>Error</MenuItem>
                                                                        }
                                                                        return(
                                                                            <MenuItem key={`Participant-${part.Customer}`} value={part.Customer}>
                                                                                {customer?.firstname} {customer?.lastname}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                </CustomeTextField>
                                                                
                                                                :
                                                                <CustomeTextField
                                                                    attr="Partner"
                                                                    label="Partner"
                                                                    object={createContract}
                                                                    setObject={setCreateContract}
                                                                    type="select"
                                                                    required
                                                                    null
                                                                    disableNoValueOption
                                                                >
                                                                    {partnerArray.map(partner =>
                                                                        <MenuItem key={`partner-${partner.id}`} value={partner.id}>
                                                                            {String(partner.Contact_Person?.Company?.company_name)}
                                                                        </MenuItem>
                                                                    )}
                                                                </CustomeTextField>
                                                            }
                                                        </Collapse>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12}>
                                <Box sx={{float: "right"}}>
                                    <Button variant="outlined" onClick={handleClose}>Abbruch</Button>
                                    <Button
                                        variant='contained'
                                        disabled={isPendingCreateContractBulk}
                                        type='submit'
                                        sx={{ml: 2}}
                                    >
                                        {isPendingCreateContractBulk ? (
                                        <Box display='flex' alignItems='center' gap={1}>
                                            <span>Erstellen</span>
                                            <CircularProgress size={20} />
                                        </Box>
                                        ) : (
                                            'Erstellen'
                                        )}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                    
                </DialogContent> 
            </Dialog>
        </>
    )
}