import React, { useCallback, useRef, useState } from "react";
import { ICustomer } from "../../interfaces/ICustomer";
import { Button } from "@mui/material";
import vCard from "vcf";
import { resolve } from "path";
import { IFeedback } from "../../interfaces/IFeedback";
import { getEmptyCustomer } from "./CustomerEdit";

interface IProps {
    handleReturnCustomer: (updatedCustomer:ICustomer) => void;
}


export const CustomerFromVCard:React.FC<IProps> = ({handleReturnCustomer}) => {
    const [isOpenUpload,setIsOpenUpload] = useState(false);
    const inputUpload = useRef<HTMLInputElement | null>(null);
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)

    const getVCardDataString = (currentRawData:undefined | vCard.Property | vCard.Property[]):string => {
        if (currentRawData instanceof vCard.Property) {
            let currentData = currentRawData.toJSON()[3];
            if (Array.isArray(currentData)) {
                return currentData.filter(x => x !== "").join(";")
            } else {
                return currentData;
            }
        }
        return "";
    }

    const vCardToCustomer = (card:vCard):ICustomer => {
        let currentContact = getEmptyCustomer();
        console.log(card)

        // Setzen vom Vor und Nachnamen
        let nData = card.get("n");
        if (nData instanceof vCard.Property) {
            let nameArray = nData.toJSON()[3];
            if (Array.isArray(nameArray)) {
                currentContact.lastname = nameArray[0];
                currentContact.firstname = nameArray.slice(1).filter(x => x !== "").join(" ")
            }
        }

        // Setzen EMail
        currentContact.email = getVCardDataString(card.get("email"));

        // TelefonData
        let telData = card.get("tel");
        if (Array.isArray(telData)) {
            if (telData.find(x => x.toJSON()[1]["type"] === "home") === undefined) {
                currentContact.telefon = getVCardDataString(telData.find(x => x.toJSON()[1]["type"] === "work"));
            } else {
                currentContact.telefon = getVCardDataString(telData.find(x => x.toJSON()[1]["type"] === "home"));
            }
            
            currentContact.mobil = getVCardDataString(telData.find(x => x.toJSON()[1]["type"] === "cell"));
        } else {
            currentContact.mobil = getVCardDataString(telData);
        }

        // Adr
        let adrData = getVCardDataString(card.get("adr"));
        if (currentContact.Address !== undefined) {
            currentContact.Address.street = adrData;
        }
        //currentContact.id = randomInt(200)*(-1);
        return currentContact;
    } 


    const castToCustomer = async (acceptedFiles: File[]) => { 
        if (acceptedFiles.length === 1) {
            let currentFile = acceptedFiles[0];
            let result_text = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsText(currentFile);
            });
            try {
                let card = new vCard().parse( String(result_text));
                let customer = vCardToCustomer(card);
                handleReturnCustomer(customer);
            } catch (error) {
                console.error(error);
                setFeedback({ open: true,message: "Die vCard konnto nicht importiert werden!", severity: "error" });
            } 
            
        }
    }

    const handleNormalUpload = (files: null | FileList) => {
        if (files !== null) {
          let acceptedFiles: File[] = Array.from(files);
          castToCustomer(acceptedFiles);
        }
    };


    return(
        <>
            <Button 
                variant="outlined"
                sx={{float: "right", mr: 2}}
                onClick={() =>
                    inputUpload !== null && inputUpload.current?.click()
                }
            >Über vCard</Button>

            <input
                hidden
                ref={inputUpload}
                type="file"
                accept="text/vcard, .vcf"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleNormalUpload(event.target.files);
                }}
            />
        </>
    )
}