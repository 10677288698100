import React, { useState } from "react";
import { ITask } from "../../../interfaces/ITask";
import { ITaskPriority } from "../../../interfaces/ITaskPriority";
import { ITaskState } from "../../../interfaces/ITaskState";
import { ITaskType } from "../../../interfaces/ITaskType";
import { IUserSmall } from "../../../interfaces/IUser";
import { Avatar, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { TaskEdit } from "../../../components/task/TaskEdit";
import { IFeedback } from "../../../interfaces/IFeedback";
import { insertIntoArray } from "../../../components/core/generic_helpders";
import { uploadFetchFeedback } from "../../../hooks/useFetch";

interface IProps {
    taskObject: ITask;
    taskPriorityArray: ITaskPriority[];
    taskStateArray: ITaskState[];
    taskTypeArray: ITaskType[];
    taskArray: ITask[];
    setTaskArray: Function;
    userSmallArray: IUserSmall[];
}


export const TaskView:React.FC<IProps> = ({
    taskObject,
    taskPriorityArray, taskStateArray, taskTypeArray,
    taskArray,setTaskArray,
    userSmallArray
}) => {
    const [currentEditor,setCurrentEditor] = useState(userSmallArray.find(x => x.profile === null ? undefined : x.profile.id === taskObject.Editor));
    const [currentPriority,setCurrentPriority] = useState(taskPriorityArray.find(x => x.id === taskObject.TaskPriority));
    //
    const [isOpenNew,setIsOpenNew] = useState(false);
    const [editTask,setEditTask] = useState(taskObject);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [feedback,setFeedback] = useState<IFeedback>({ open: false,message: "Gespeichert!", severity: "success" } as IFeedback)

    const handleAddNewTask = () => {
        setEditTask(taskObject);
        setIsOpenNew(true);
    }


    const handleAfterSave = (newObject:ITask) => {
        setTaskArray(insertIntoArray(newObject,taskArray));
        setEditTask(newObject);
        setIsOpenNew(false);
    }
    const handleNew = () => {
        uploadFetchFeedback(`/task/${editTask.id}`,false,editTask,handleAfterSave,setIsLoading,setFeedback)
    }


    function stringToColor(string: string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

      
    function stringAvatar(name: string) {
        return {
          sx: {
            bgcolor: stringToColor(name),
            width: 40,
            height: 40,
          },
          children:
            name.length === 0
              ? ''
              : name.split(' ').length === 1
                ? name[0]
                : `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

    return(
        <>
            <Dialog open={isOpenNew} onClose={() => setIsOpenNew(false)} maxWidth="sm"fullWidth>
                <DialogTitle>Aufgabe Bearbeiten</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}/>
                    <TaskEdit
                        key={`edit-task-${editTask.id}`}
                        editTask={editTask}
                        setEditTask={setEditTask}
                        taskArray={taskArray}
                        taskPriorityArray={taskPriorityArray}
                        taskStateArray={taskStateArray}
                        taskTypeArray={taskTypeArray}
                        userSmallArray={userSmallArray}
                    />
                </DialogContent>
                <DialogActions>
                    { (taskObject.closed_at === null) ?
                        <>
                            <Button variant="outlined" disabled={isLoading} onClick={() => setIsOpenNew(false)}>Abbruch</Button>
                            <Button onClick={handleNew} variant="contained" disabled={isLoading || editTask.title === ""}>Speichern</Button>
                        </>
                        :
                        <>
                            <Button variant="contained" disabled={isLoading} onClick={() => setIsOpenNew(false)}>Okay</Button>
                        </>
                    }

                </DialogActions>
            </Dialog>

            <Card variant="outlined" sx={{ cursor: 'pointer', mt: 2 , mr: 2}} onClick={handleAddNewTask}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Avatar
                                {...stringAvatar(
                                    `${currentEditor?.first_name} ${currentEditor?.last_name}`
                                )}
                                alt={`${currentEditor?.first_name} ${currentEditor?.last_name}`}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <Typography variant="h6" sx={{textAlig: "center", width: "100%"}}>{editTask.title}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Chip 
                                color={
                                    editTask.TaskPriority <= 2 ? undefined
                                    : editTask.TaskPriority === 3 ? "warning"
                                    : "error" 
                                } 
                                label={currentPriority?.taskPriority} 
                                sx={{width: "100%"}}
                            />
                        </Grid>
                    </Grid>
                    
                </CardContent>
            </Card>

        
        </>
    )
}