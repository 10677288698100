import React, {useState, useEffect} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {DataGrid, GridColDef, GridRowId, GridRowParams, deDE} from "@mui/x-data-grid";
import {ParticipantsTableRow} from "./ParticipantsTableRow";
import {ICustomer} from "../../../interfaces/ICustomer";
import {IProject} from "../../../interfaces/IProject";

import { IGender } from "../../../interfaces/IGender";
import { ITitle } from "../../../interfaces/ITitle";
import { uploadFetch } from "../../../hooks/useFetch";
import { getEmptyCustomer } from "../CustomerEdit";
import { CustomerEdit } from "../CustomerEdit";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";
import { IInsurance } from "../../../interfaces/IInsurance";
import { IPropertyManagment } from "../../../interfaces/IPropertyManagement";
import { IAddressType } from "../../../interfaces/IAddress";
import { IProjectParticipant } from "../../../interfaces/IProjectParticipant";
import ValidationDialog from "../../../sites/ValidationDialog";

const columns: GridColDef[] = [
    {
        field: 'customerNumber',
        headerName: 'Nr.',
        valueGetter: (params) => params.row.id,
        width: 70
    },
    {
        field: 'firstname',
        headerName: 'Vorname',
        valueGetter: (params) => params.row.firstname,
        width: 200
    },
    {
        field: 'lastname',
        headerName: 'Name',
        valueGetter: (params) => params.row.lastname,
        width: 200
    },
    {
        field: 'mobil',
        headerName: 'Mobil',
        valueGetter: (params) => params.row.mobil,
        width: 150
    },
    {
        field: 'email',
        headerName: 'Email',
        valueGetter: (params) => params.row.email,
        width: 200
    }
]

interface IProps {
    projectObject: IProject;
    genderArray?: IGender[];
    titleArray?: ITitle[];
    addressTypeArray: IAddressType[];
    insuranceArray: IInsurance[];
    propertyManagementArray: IPropertyManagment[];
    customerArray: ICustomer[]
    participants: IProjectParticipant[];
    setProjectObject: Function;
    setCustomerArray: Function;
    setInsuranceArray: Function;
    setPropertyManagementArray: Function;
    application: boolean;
}


export const ParticipantsTable: React.FC<IProps> = ({
  projectObject,
  genderArray,
  titleArray,
  addressTypeArray,
  customerArray,
  setCustomerArray,
  setInsuranceArray,
  setPropertyManagementArray,
  propertyManagementArray,
  insuranceArray,
  participants,
  setProjectObject,
  application
}) => {

    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [isOpenNewCustomer, setIsOpenNewCustomer] = useState(false);

    const [addNewParticipant, setAddNewParticipant] = useState<number[]>([]);
    const [editCustomer, setEditCustomer] = useState(getEmptyCustomer());

    const [feedback, setFeedback] = useState<IFeedback>({open: false, message: '', severity: 'info'});
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [isLoadgin, setIsLoading] = useState(false);

    // Suche
    const [searchTerm, setSearchTerm] = useState("");

    //RequiredFields
    const [areRequiredFieldsSet, setAreRequiredFieldsSet] = useState(false)
    const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
    const [proceedWithSave, setProceedWithSave] = useState(false);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [attemptingSave, setAttemptingSave] = useState(false);

    const handleValidationDialogClose = () => {
        setIsValidationDialogOpen(false);
        setProceedWithSave(false);
    };

    const handleValidationDialogProceed = () => {
        setIsValidationDialogOpen(false);
        setProceedWithSave(true);
        setAttemptingSave(true);
    };




    const handleSearchTerm = () => {
        if (customerArray === undefined) {
            return [];
        }
        else if (searchTerm === "") {
            return customerArray
        } else {
            return customerArray.filter(x =>
                String(x.lastname).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.firstname).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.telefon).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.mobil).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.email).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({open: true, message: "Ein fehler ist aufgetretten!", severity: "error"})
            setIsLoading(false);
        }
    }, [wasSuccessfully])

    const handleAddNew = () => {
        setAddNewParticipant([]);
        setIsOpenAdd(true);
    };

    const createNewParticipantFromArray = (customerIdArray:number[]) => {
        let currentParticipantArray = [...projectObject.Participant];
        let tmpId = -1;

        if (currentParticipantArray.length > 0) {
            let tmpIdArray = Math.min(...currentParticipantArray.map(x => x.id)) -1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray - 1;
            }
        }

        customerIdArray.map((x,i) => {
            let newParticipant = {
                is_contact_person: currentParticipantArray.length === 0,
                is_payee: currentParticipantArray.length === 0,
                enable_hv: false,
                enable_pk: false,
                id: (tmpId-i),
                Customer: x,
                Project: projectObject.id
            } as IProjectParticipant
            currentParticipantArray.push(newParticipant)
        })

        setProjectObject({
            ...projectObject,
            Participant: currentParticipantArray
        } as IProject)

    }


    const handleAddBtn = () => {
        createNewParticipantFromArray(addNewParticipant);
        setAddNewParticipant([]);
        setIsOpenAdd(false);

        /*
        const newParticipants = [
            ...projectObject.Participant,
            ...addNewParticipant.filter((x) => !projectObject.Participant.includes(x))
        ];
        set
        setParticipantsArray(newParticipants, "add")
        setIsOpenAdd(false);
        */
    };

    const handleRowSelectionChange = (newRowSelectionModel: GridRowId[]) => {
        const newSelectedIds = newRowSelectionModel.map(id => Number(id));
        setAddNewParticipant(newSelectedIds);
    };

    /*
// Here all the magic happens: all Ids, will be inserted into the projectObject via their Ids.
    const setParticipantsArray = (newParticipantIds: number | number[], action: 'add' | 'remove' | 'update') => {
        if (action === "add" && Array.isArray(newParticipantIds)) {
            setProjectObject({
                ...projectObject,
                Participant: [
                    ...projectObject.Participant,
                    ...newParticipantIds.filter(id => !projectObject.Participant.includes(id))
                ]
            } as IProject)
            console.log(projectObject)
        } else if (action === "add" && typeof newParticipantIds === "number") {
            console.log("I am adding somebody to the Participant Array!")
            setProjectObject({
                ...projectObject,
                Participant: [
                    ...projectObject.Participant,
                    newParticipantIds
                ]
            } as IProject)
            console.log(projectObject)
        } else if (action === "remove" && typeof newParticipantIds === "number") {
            const filteredParticipants = projectObject.Participant.filter(id => id !== newParticipantIds);
            setProjectObject({
                ...projectObject,
                Participant: filteredParticipants
            });
            console.log(projectObject)
        } else {
            console.error('Invalid action or participant ID format:', action, newParticipantIds);
        }
    };
    */
    
    const handleAfterSave = (savedCustomer: ICustomer, action: 'add' | 'update') => {
        if (action === "add") {
            setCustomerArray((prevCustomers: ICustomer[]) => {
                const existingCustomerIndex = prevCustomers.findIndex(c => c.id === savedCustomer.id);
                let result;
                if (existingCustomerIndex > -1) {
                    result = prevCustomers.map(customer =>
                        customer.id === savedCustomer.id ? savedCustomer : customer
                    );
                } else {
                    result = [...prevCustomers, savedCustomer];
                }
                return result;
            });
            createNewParticipantFromArray([savedCustomer.id]);

            //setProjectObject({
            //    ...projectObject, 
            //    Participant: updatedArray
            //})
            //setParticipantsArray(savedCustomer.id, "add");

        } else if (action === "update") {
            setCustomerArray((prevCustomers: ICustomer[]) => {
                const existingCustomerIndex = prevCustomers.findIndex(c => c.id === savedCustomer.id);
                const updatedCustomers = [...prevCustomers];
                updatedCustomers[existingCustomerIndex] = savedCustomer;
                return updatedCustomers;
            });
        } else {
            console.log("Something went sideways!")
        }
    };
//functions for new Customer, first handleSave, then handleAfterSave. 
//handleAddNewCustomer opens the Dialog for the EditCustomer Component, there you can add a new customer.
    const handleAddNewCustomer = () => {
        setIsOpenNewCustomer(true)
    }

    const handleSave = async () => {
        if (!areRequiredFieldsSet && !proceedWithSave) {
            setIsValidationDialogOpen(true);
            return;
        }
        const uploadObject: ICustomer = {
            ...editCustomer,
            Address: editCustomer.b2b ? undefined : editCustomer.Address,
            Company: editCustomer.b2b ? editCustomer.Company : undefined,
        };
        try {
            uploadFetch("/customer/", true, uploadObject, (e: any) => {
                handleAfterSave(e, 'add')
            }, setWasSuccessfully, () => {
            }, setIsLoading);
        } catch (error) {
            console.error("Failed to save customer:", error);
            setWasSuccessfully(false);
        } finally {
            setIsLoading(false);
            setIsOpenNewCustomer(false)
        }

    };

    useEffect(() => {
        if (attemptingSave) {
            handleSave();
        }
    }, [attemptingSave]);


  return (
      <>
      <CustomSnackbar feedback={feedback} setFeedback={setFeedback} />
        <ValidationDialog
            open={isValidationDialogOpen}
            handleClose={handleValidationDialogClose}
            handleProceed={handleValidationDialogProceed}
            missingFields={missingFields}
        />

        <Dialog open={isOpenAdd}  onClose={() => setIsOpenAdd(false)} maxWidth="md" fullWidth>
            <DialogTitle>Kunden hinzufügen</DialogTitle>
            <DialogContent>

                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />

                <DataGrid
                    sx={{mt: 2}}
                    autoHeight
                    checkboxSelection 
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={handleSearchTerm()}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    rowSelectionModel={addNewParticipant}
                    columns={columns}
                    isRowSelectable={(params: GridRowParams) => !participants.map(x => x.Customer).includes(params.id as number)}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setIsOpenAdd(false)}>Abbruch</Button>
                <Button variant="contained" onClick={handleAddBtn}>Hinzufügen</Button>
            </DialogActions>
        </Dialog>


            <Typography variant="h6">
                Zugeordnete Kunden
                <Button variant="outlined" sx={{float: "right"}} onClick={handleAddNew}>Kunden zuordnen</Button>
                {!application &&
                    <Button variant="outlined" sx={{float: "right", marginRight: 2}} onClick={handleAddNewCustomer}>Kunden
                        erstellen</Button>}

            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.</TableCell>
                        <TableCell>Kunde</TableCell>
                        <TableCell title="Anspreachpartner" sx={{width: 50}}>AP</TableCell>
                        <TableCell title="Schuldner">ZE</TableCell>
                        <TableCell title="Wohnt im Haushalt mit Pflegekasse">IM </TableCell>
                        <TableCell title="Hat Pflegekasse/-stufe">PK</TableCell>
                        <TableCell title="Hat Hausverwaltung">HV</TableCell>
                        <TableCell>Mobil</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projectObject.Participant.map(participantId => {
                      let customerIdArray = participants.map(x => x.Customer);
                      const customer = customerArray.find(c => c.id === participantId.Customer);
                      if (customer === undefined) { return <></> }
                      else {
                        return(
                            <ParticipantsTableRow
                                key={`participant-${participantId.id}`}
                                customer={customer}
                                participant={participantId}
                                genderArray={genderArray || []}
                                insuranceArray={insuranceArray || []}
                                setInsuranceArray={setInsuranceArray}
                                setPropertyManagementArray={setPropertyManagementArray}
                                addressTypeArray={addressTypeArray}
                                propertyManagementArray={propertyManagementArray || []}
                                titleArray={titleArray || []}

                                customerArray={customerArray}
                                setCustomerArray={setCustomerArray}

                                participantArray={projectObject.Participant}
                                setParticipantArray={(updatedArray:IProjectParticipant[]) => setProjectObject({...projectObject, Participant: updatedArray} as IProject)}
                            />
                        )
                      }
                  })}
              </TableBody>
          </Table>
          <Dialog open={isOpenNewCustomer} onClose={() => setIsOpenNewCustomer(false)} maxWidth="md" fullWidth>
            <DialogTitle>Neuen Beteiligten anlegen & zuordnen</DialogTitle>
                <DialogContent>
                    <CustomerEdit
                          key={`editCustomer-${editCustomer.id}`}
                          insuranceArray={insuranceArray || []}
                          setInsuranceArray={setInsuranceArray}
                          genderArray={genderArray || []}
                          addressTypeData={addressTypeArray|| []}
                          titleArray={titleArray || []}
                          propertyManagementArray={propertyManagementArray || []}
                          setPropertyManagementArray={setPropertyManagementArray} 
                          customerObject={editCustomer}
                          setCustomerObject={setEditCustomer}
                          areRequiredFieldsSet={areRequiredFieldsSet}
                          setAreRequiredFieldsSet={setAreRequiredFieldsSet}
                          setMissingFields={setMissingFields} //
                      />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenNewCustomer(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};