import React, { useEffect, useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IPartner } from "../../interfaces/IPartner";
import { ICustomer } from "../../interfaces/ICustomer";
import { ICompany } from "../../interfaces/ICompany";
import { IGender } from "../../interfaces/IGender";
import { ITitle } from "../../interfaces/ITitle";
import { IPostcode } from "../../interfaces/IPostcode";
import { CustomerEdit } from "../customer/CustomerEdit";
import { getEmptyCompany } from "../company/CompanyEdit";
import { IAddressType } from "../../interfaces/IAddress";

interface IProps {
    partnerObject: IPartner;
    setPartnerObject: Function;
    addressTypeArray: IAddressType[];
    genderArray: IGender[];
    titleArray: ITitle[];
}

export const PartnerEdit:React.FC<IProps> = ({partnerObject, addressTypeArray, setPartnerObject, genderArray, titleArray}) => {
    const [customerObject,setCustomerObject] = useState(partnerObject.Contact_Person);

    useEffect(() => {
        setPartnerObject({
            ...partnerObject,
            Contact_Person: {...customerObject, Address: undefined} as ICustomer
        })
    },[customerObject])

    return(
        <>
            <Grid container spacing={2} sx={{mt: 1}}>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Handwerker"
                        attr="is_partner"
                        type="boolean"
                        object={partnerObject}
                        setObject={setPartnerObject}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Lieferant"
                        attr="is_material_supplier"
                        type="boolean"
                        object={partnerObject}
                        setObject={setPartnerObject}
                        required
                    />
                </Grid>
            </Grid>

            <CustomerEdit
                key={`editCustomer-${customerObject.id}`}
                customerObject={customerObject}
                addressTypeData={addressTypeArray}
                setInsuranceArray={()=>{}}
                setPropertyManagementArray={()=>{}}
                setCustomerObject={setCustomerObject}
                insuranceArray={[]}
                genderArray={genderArray}
                titleArray={titleArray}
                disableB2bSelection
            />

            <Grid container spacing={2} sx={{mt: -1}}>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="EK Stundenpreis"
                        attr="purchasing_price"
                        type="number"
                        object={partnerObject}
                        setObject={setPartnerObject}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="VK Stundenpreis"
                        attr="selling_price"
                        type="number"
                        object={partnerObject}
                        setObject={setPartnerObject}
                        required
                    />
                </Grid>
            </Grid>
        </>
    )
}