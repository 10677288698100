import React, {useEffect, useState} from "react";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { uploadFetchFeedback } from "../../hooks/useFetch";
import { IFeedback } from "../../interfaces/IFeedback";
import { Box, Button, Card, CardContent, Grid, LinearProgress, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { CustomEditor } from "../core/CustomEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IDictionaryEntry } from "../../interfaces/IDictionaryEntry";
import { IOfferState } from "../../interfaces/IOfferState";
import { IProjectState } from "../../interfaces/IProjectState";
import { ITaskState } from "../../interfaces/ITaskState";
import { IBillState } from "../../interfaces/IBillState";
import { ISystemMailAutomation } from "../../interfaces/ISystemMailAutomation";
import { CustomeTextField } from "../core/CustomeTextField";
import { ISystemMailSendCategory } from "../../interfaces/ISystemMailSendCategory";
import { SystemMailAutomationTable } from "./SystemMailAutomationTable";
import { tr } from "date-fns/locale";


export const getEmptrySystemMail = () => {
    return {
        body: "",
        DictonaryArray: [],
        id: -1,
        is_edit:false,
        is_edit_admin: true,
        subject: "",
        SystemMailAutomationArray: [],
        SystemMailCategory: 1,
        title: ""
    } as ISystemMail
}



interface IProps {
    currentId: number;
    setCurrentId: Function;
    currentArray: ISystemMail[];
    setCurrentArray: Function;
    setWasSaved: Function;
    setWasSavedSuccesfully: Function;
    dictionaryEntryArray: IDictionaryEntry[];
    // Für Automatisierung
    systemMailSendCategoryArray: ISystemMailSendCategory[];
    offerStateArray: IOfferState[];
    projectStateArray: IProjectState[];
    taskStateArray: ITaskState[];
    billStateArray: IBillState[];
}

export const SystemMailEdit: React.FC<IProps> = (props) => {
    const [systemMailOrg, setSystemMailOrg] = useState(props.currentArray.find(x => x.id === props.currentId));
    const [systemMailObject, setSystemMailObject] = useState(
        props.currentId === -1 
        ? {
            body: "", 
            DictonaryArray: [], 
            id: -1, 
            is_edit: false, 
            is_edit_admin: true,
            subject: "",
            SystemMailAutomationArray: [],
            SystemMailCategory: 1,
            title: ""
        } as ISystemMail
        : systemMailOrg
    );
    //
    const [automation,setAutomation] = useState<ISystemMailAutomation[]>(systemMailObject === undefined ? [] : systemMailObject.SystemMailAutomationArray)
    //
    const [isLoading,setIsLoading] = useState(false)
    const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 


    const setText = (txt: string) => {
        //console.log("INSIDE");
        //console.log(txt);
        setSystemMailObject({... systemMailObject!, body : txt});
        /*
        console.log("INSIDE");
        console.log(txt);
        setSettingObject({
            ...settingObject,
            SettingValue: txt
        })
        if(settingObject?.SettingValue !== undefined && systemMailObject !== undefined)
        {
            setSystemMailObject({... systemMailObject, Body : settingObject.SettingValue})
        }
        */
    }


    const isDisabled = () => {
        if (isLoading) {
            return true;
        }
        else if (systemMailObject !== undefined) {
            return (
                systemMailObject.title === ""
                || systemMailObject.subject === ""
                || systemMailObject.body === ""
            )
        } else {
            return true;
        }
    }

    const wrapperSaved = (localObject: ISystemMail) => {
        if (props.currentId < 0) {
            props.setCurrentArray([
                ...props.currentArray,
                localObject
            ])
        } else {
            props.setCurrentArray([
                ...props.currentArray.map(x => x.id === localObject.id ? localObject : x)
            ])
        }
        setSystemMailOrg(localObject);
        setSystemMailObject(localObject);
        setAutomation(localObject.SystemMailAutomationArray)
        props.setCurrentId(localObject.id);
    }


    const handleSave = () => {
        if (systemMailObject !== undefined) {
            let uploadObeject: ISystemMail = {
                ...systemMailObject,
                SystemMailAutomationArray: automation.map(x => {return {...x, SystemMail: systemMailObject.id} as ISystemMailAutomation})
            }
            uploadFetchFeedback((props.currentId === -1) ? `/systemmail/` : `/systemmail/${systemMailObject.id}`,
                (props.currentId === -1),
                uploadObeject,
                wrapperSaved,
                setIsLoading,
                setFeedback
                
            );
        }
    }

    if ( (props.currentId !== -1 && systemMailOrg === undefined) || systemMailObject === undefined) {
        return <>Bitte warten...</>
    }
    else {
        return (
            <>
                <Typography variant="h5">
                    {(systemMailOrg === undefined) ? "Neue Systemnachricht" : `${systemMailOrg.title}`}
                </Typography>
                {(isLoading) && <LinearProgress/>}
                <Box sx={{mt: 2}}/>

                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <CustomeTextField
                            label="Editierbar durch MA"
                            attr="is_edit"
                            object={systemMailObject}
                            setObject={setSystemMailObject}
                            type="boolean"
                        />
                    </Grid>
                    <Grid item sm={8}>
                        <CustomeTextField
                            label="Kategorie"
                            attr="SystemMailCategory"
                            object={systemMailObject}
                            setObject={setSystemMailObject}
                            type="select"
                            required
                        >
                            {props.systemMailSendCategoryArray.map(x =>
                                <MenuItem key={`sysmail-${x.id}`} value={x.id}>
                                    {x.systemMailSendCategory}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            label="Titel (nur intern)"
                            size="small"
                            value={systemMailObject.title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, title: event.target.value})}
                            error={systemMailObject.title === ""}
                            fullWidth
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            label="Betreff"
                            size="small"
                            value={systemMailObject.subject}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, subject: event.target.value})}
                            error={systemMailObject.subject === ""}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{mt: 5}}/>
                {(props.currentId === -1) 
                    ? <Card sx={{mt: 5}} variant="outlined"><CardContent>Automatisierungen sind nach dem Speichern der Systemnachricht verfügbar!</CardContent></Card>
                    : <SystemMailAutomationTable
                        automation={automation}
                        setAutomation={setAutomation}
                        systemMailSendCategoryArray={props.systemMailSendCategoryArray}
                        billStateArray={props.billStateArray}
                        offerStateArray={props.offerStateArray}
                        projectStateArray={props.projectStateArray}
                        taskStateArray={props.taskStateArray}
                    />
                }

                <Box sx={{mt: 5}}/>

                <Grid container spacing={2}>
                    <CustomEditor 
                        txt={systemMailObject.body} 
                        setTxt={setText}
                        dictonaryIdsArray={systemMailObject.DictonaryArray}
                        dictionaryEntryArray={props.dictionaryEntryArray}
                    />
                </Grid>


                <Box sx={{mt: 2}}/>
                <Button onClick={handleSave} sx={{float: "right", ml: 2}} variant="contained"
                        disabled={isDisabled()}>Speichern</Button>
                <Button onClick={() => props.setCurrentId(null)} sx={{float: "right"}}
                        variant="outlined">Abbruch</Button>
            </>
        )
    }

}