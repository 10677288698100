import React, { Dispatch, useState } from "react";
import { IProject } from "../../../interfaces/IProject";
import { IContractState } from "../../../interfaces/IContractState";
import { IContractType } from "../../../interfaces/IContractType";
import { IContract } from "../../../interfaces/IContract";
import { AppBar, Box, Dialog, DialogContent, IconButton, Slide, TableCell, TableRow, Toolbar, Typography } from "@mui/material";
import { Download, Edit, NoteAlt } from "@mui/icons-material";
import { useGenerateContractDocument } from "../../../hooks/data/data_contract";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";
import { IDocument } from "../../../interfaces/IDocument";
import { base64Download } from "../../core/generic";
import { ITemplate } from "../../../interfaces/ITemplate";
import { TransitionProps } from "@mui/material/transitions";
import { GridCloseIcon } from "@mui/x-data-grid";
import { SignedContract } from "./SignedContract";
import { EditorContract } from "./EditorContract";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { IService } from "../../../interfaces/IService";
import { IUnit } from "../../../interfaces/IUnit";
import { IServiceCategory } from "../../../interfaces/IServiceCategory";



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




interface IProps {
    project: IProject;
    contractObject: IContract;
    setContractArray: Dispatch<React.SetStateAction<IContract[]>>
    contractStateArray: IContractState[];
    contractTypeArray: IContractType[];
    //
    templateArray: ITemplate[];
    serviceCategoryArray: IServiceCategory[];
    serviceArray: IService[];
    unitArray: IUnit[];
    //
    refetchContractArray: (options?: RefetchOptions) => Promise<QueryObserverResult<IContract[], Error>>
} 

export const ContractRow:React.FC<IProps> = ({
    project,
    contractObject,
    setContractArray,
    contractStateArray,
    contractTypeArray,
    templateArray,
    serviceCategoryArray,
    serviceArray,
    unitArray,
    refetchContractArray
}) => {
    const { generateContractDocument, isPendingGenerateContractDocument } = useGenerateContractDocument();
    const [generatedData,setGeneratedData] = useState<IDocument|null>(null);
    //
    const [editContract,setEditContract] = useState(contractObject);
    //
    const [isOpenEditor,setIsOpenEditor] = useState(false);
    const [isOpenSigned,setIsOpenSigned] = useState(false);
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)


    const handleOpenEditor = () => {
        setEditContract(contractObject);
        setIsOpenEditor(true);
    }


    const generatePdf = async (callFunction: (data:IDocument) => void) => {
        if (generatedData === null || true) {
            try {
                const dataFromBackend = await generateContractDocument(contractObject.id);
                callFunction(dataFromBackend);
                setGeneratedData(dataFromBackend)
            } catch (error) { 
                setFeedback({
                    open: true,
                    message: "Die Dokumente konnten nicht generiert werden!!",
                    severity: "error"
                })
            }
        } else {
            callFunction(generatedData!);
        }

    }


    return(
        <>
            <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>


            {(isOpenEditor) &&
                <Dialog
                    fullScreen
                    open={isOpenEditor}
                    onClose={() => setIsOpenEditor(false)}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setIsOpenEditor(false)}
                            aria-label="close"
                        >
                        <GridCloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Vertragseditor
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <Box sx={{m: 5}}>
                        <EditorContract
                            key={`editor-${contractObject.id}`}
                            project={project}
                            orgContract={contractObject}
                            editContract={editContract}
                            setEditContract={setEditContract}
                            contractStateArray={contractStateArray}
                            contractTypeArray={contractTypeArray}
                            serviceCategoryArray={serviceCategoryArray}
                            serviceArray={serviceArray}
                            unitArray={unitArray}
                            handleClose={() => setIsOpenEditor(false)}
                            handleSave={refetchContractArray}
                        />
                        
                    </Box>
                </Dialog>
            }

            {(isOpenSigned) &&
                <Dialog
                    fullScreen
                    open={isOpenSigned}
                    onClose={() => setIsOpenSigned(false)}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setIsOpenSigned(false)}
                            aria-label="close"
                        >
                        <GridCloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Kundenansicht
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <Box sx={{m: 5}}>
                        <SignedContract
                            key={`signed-${contractObject.id}`}
                            generatedData={generatedData}
                            idContract={contractObject.id}
                            enablePartnerSigned={contractObject.ContractType === 30}
                            handleClose={() => setIsOpenSigned(false)}
                            handleSave={() => { refetchContractArray();setIsOpenSigned(false);}}
                        />
                        
                    </Box>
                </Dialog>
            }

            {(isPendingGenerateContractDocument) &&
                <Dialog open>
                    <DialogContent>
                        Dokumente werden generiert...
                    </DialogContent>
                </Dialog>
            }


            <TableRow key={`contract-${contractObject.id}`}>
                <TableCell>
                    {contractObject.id}
                </TableCell>
                <TableCell>
                    {contractTypeArray.find(x => x.id === contractObject.ContractType)?.contractType}
                </TableCell>
                <TableCell>
                    {contractStateArray.find(x => x.id === contractObject.ContractState)?.contractState}
                </TableCell>
                <TableCell>
                    {contractObject.title}
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleOpenEditor}><Edit/></IconButton>
                    {(contractObject.ContractDocuments.length === 0)?
                        <>
                            <IconButton onClick={() => generatePdf((a:any) => setIsOpenSigned(true))}><NoteAlt/></IconButton>
                            <IconButton onClick={() => generatePdf(base64Download)}><Download/></IconButton>
                        </>
                        :
                        <>
                            <IconButton onClick={() => base64Download(contractObject.ContractDocuments[0].Document_Signed)}><Download/></IconButton>
                        </>
                    }

                </TableCell>
            </TableRow>
        </>
    )
}